import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { HasuraServices } from '../application/protocols/services/api_services';
import { useProfileContext } from '../application/stores/ProfileContext';
import CustomButton from '../components/CustomButton';
import CustomTextField from '../components/CustomTextField';
import AuthHeader from '../components/widgets/AuthHeader';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { profile } = useProfileContext();

  const [email, setEmail] = useState(String(profile?.user?.profile ?? ''));

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    const query = `mutation Mymutation {
      reset_password(email:"${email}"){
        access
        message
      }
    }`;
    const res = await HasuraServices.instance.query(query);
    if (
      res.msg &&
      res.msg.reset_password.message ==
        'Please check email your email for password reset steps.'
    ) {
      navigate(`/forgot-password-verify/${email}`);
    } else if (
      res.msg &&
      res.msg.reset_password.message == 'Email does not exists.'
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `Couldn't find your account. Please try again after sometime.`,
        footer:
          'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `Couldn't process your request. Please try again after sometime.`,
        footer:
          'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
      });
    }
  };

  useEffect(() => {
    setEmail(String(profile?.user?.email));
  }, [profile]);

  return (
    <div className="bg-mainbackground h-screen  font-times text-font ">
      <div className="justify-center items-center bg-secondbackground w-full md:w-2/3 md:max-w-[600px] mb-auto h-auto mx-auto rounded-2xl">
        <AuthHeader />
        <div className="mx-auto px-8 py-12 h-md">
          <h1 className="text-primary text-center"> Forgot Password</h1>
          <CustomTextField
            name={'email'}
            value={email}
            hintText={'Enter your email'}
            label={'Email'}
            onChange={handleInputChange}
          />
          <CustomButton label="Send Code" onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
