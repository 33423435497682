import React, { createContext, useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { generateAlphanumericString } from '../protocols/guard/guard';
import { HasuraServices } from '../protocols/services/api_services';
import { useAppContext } from './AppContext';
import { useMarketContext } from './MarketContext';
import { useProfileContext } from './ProfileContext';

const InnovationContext = createContext();

export const InnovationProvider = ({ children }) => {
  const { userId } = useProfileContext();
  const { base_url } = useAppContext();
  const [createdCardsList, setCreatedCardsList] = useState([]);
  const { forceFetchBots } = useMarketContext();
  const [progress, setProgress] = useState(2);
  const [loading, setLoading] = useState(false);
  const [createdPackageList, setCreatedPackageList] = useState([
    'package1',
    'package2',
    'package3',
    'package4',
    'package5',
    'package6',
  ]);

  const clearData = () => setCreatedCardsList([]);

  const getCreatedCardList = async () => {
    if (userId) {
      const getCreatedCardsQuery = `query GetCardInformation($user_id:uuid!){
      cards(where:{user_id:{_eq:$user_id}}){
          card_id
          category
    			created_at
          frontend_config{
          config_main
          }
          card_creation_process{
            id
            title
            detailed_description
            input_template
            input_template_example
            output_template
            output_template_example
            card_file{
              url_name
            }
            variables
          }
      }
  }`;

      const getCreatedCardsVariables = {
        user_id: userId,
      };

      const res = await HasuraServices.instance.query(
        getCreatedCardsQuery,
        getCreatedCardsVariables
      );

      const actual_list = res.msg?.cards;
      setCreatedCardsList(
        actual_list
          ?.map((cardData) => ({
            ...cardData.frontend_config[0].config_main,
            purpose_category: cardData?.category,
            created_date: cardData?.created_at,
            inputTemplate: cardData?.card_creation_process?.input_template,
            inputTemplateExample:
              cardData?.card_creation_process?.input_template_example,
            outputTemplate: cardData?.card_creation_process?.output_template,
            outputTemplateExample:
              cardData?.card_creation_process?.output_template_example,
            cardCreationID: cardData?.card_creation_process?.id,
            image_addr: `${base_url}card-image${cardData.frontend_config[0].config_main.image_addr}`,
            actionAreaKey: generateAlphanumericString(4),
            id: cardData.card_id,
            card_files: cardData?.card_creation_process?.card_file?.map(
              (card_file_link) => ({
                image: card_file_link?.url_name,
                id: generateAlphanumericString(5),
              })
            ),
            variables: cardData?.card_creation_process?.variables,
          }))
          .sort(
            (first, second) =>
              new Date(second.created_date) - new Date(first.created_date)
          )
      );
    }
  };

  const deleteCreatedCard = (bot) => {
    const query = `mutation MyMutation($card_name:String!){
      delete_card(args:{card_name:$card_name}){
        message
        status
      }
    }`;
    const variables = {
      card_name: bot.id,
    };
    HasuraServices.instance.query(query, variables).then(async (res) => {
      if (
        res.msg.delete_card.status == '200' &&
        res.msg.delete_card.message == 'Card deleted successfully'
      ) {
        setTimeout(async () => {
          setLoading(false);
          setProgress(2);
          await Swal.fire({
            icon: 'success',
            text: 'Card deleted successfully.',
            confirmButtonColor: '#521E95',
            footer:
              'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
          });

          await forceFetchBots();
          setProgress(0);
          await getCreatedCardList();
        }, 3000);
      } else {
        setLoading(false);
        setProgress(0);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Couldn't delete your card. Please try again after sometime.`,
          footer:
            'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
        });
      }
    });
  };

  const fetchCreatedPackages = async () => {
    const query = `query MyQuery($user_id:uuid!){
      card_package(where:{user_id:{_eq:$user_id}}){
        package_id
      }
    }
    `;
    await HasuraServices.instance
      .query(query, { user_id: userId }, 'premium')
      .then((res) => {
        setCreatedPackageList(
          res?.msg?.card_package?.map((packageName) =>
            packageName.package_id.toLowerCase()
          )
        );
      });
  };

  const insertNewCardPackage = async (packageName) => {
    const res = await HasuraServices.instance.query(
      `mutation MyMutation{
    insert_card_package_one(object:{package_id:"${packageName}"}){
      package_id
    }
  }`,
      {},
      'premium'
    );
    if (res.msg) {
      fetchCreatedPackages();
      Swal.fire({
        title: 'Card package created successfully',
      });
    } else {
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `Could not create your package.`,
        footer:
          'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
      });
    }
  };

  useEffect(() => {
    if (userId) {
      getCreatedCardList();
      fetchCreatedPackages();
    }
  }, [userId]);

  return (
    <InnovationContext.Provider
      value={{
        createdCardsList,
        clearData,
        deleteCreatedCard,
        progress,
        setProgress,
        loading,
        setLoading,
        createdPackageList,
        fetchCreatedPackages,
        getCreatedCardList,
        insertNewCardPackage,
      }}
    >
      {children}
    </InnovationContext.Provider>
  );
};

export const useInnovationContext = () => {
  return useContext(InnovationContext);
};
