import DiamondSharpIcon from '@mui/icons-material/DiamondSharp';
import InventoryIcon from '@mui/icons-material/Inventory';
import { useTranslation } from 'react-i18next';
import { BsActivity, BsInfoCircle, BsStar } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { useAppContext } from '../application/stores/AppContext';
import Favourite from '../pages/FavouritePage';
import Info from '../pages/Info';
import InnovationLabPage from '../pages/lab/InnovationLabPage';
import Store from '../pages/Store';

const Sidebar = () => {
  const { windowWidth, windowHeight } = useAppContext();
  const [t] = useTranslation('global');

  const menus = [
    {
      name: t('buttons.store'),
      icon: BsActivity,
      route: '/market',
      screen: Store,
    },
    {
      name: t('buttons.collection'),
      icon: InventoryIcon,
      route: '/collection',
      screen: InnovationLabPage,
    },
    {
      name: t('buttons.favourite'),
      icon: BsStar,
      route: '/favorite',
      screen: Favourite,
    },
    {
      name: t('buttons.lab'),
      icon: DiamondSharpIcon,
      route: '/lab',
      screen: InnovationLabPage,
    },
    {
      name: t('buttons.info'),
      icon: BsInfoCircle,
      route: '/info',
      screen: Info,
    },
  ];
  const location = useLocation();
  const desiredHeight = windowHeight - 120;
  return (
    <div
      style={{ height: desiredHeight }}
      className={`${
        windowWidth > 500 ? '' : 'hidden'
      } h-full min-h-full flex flex-col justify-between bg-secondbackground items-center w-52 min-w-52 max-w-52`}
    >
      <div
        className="w-full mt-8 flex flex-col gap-2
      "
      >
        {menus.map((e, index) => {
          const active =
            location.pathname.includes(e.route) &&
            !location.pathname.includes(e.route + '/');
          const Icon = e.icon;
          return (
            <Link to={e.route} className="w-full" key={index + 1}>
              <div
                className={`flex gap-1 flex-row justify-start items-center p-3 mx-4  rounded-xl ${
                  active
                    ? 'bg-primary'
                    : 'bg-secondbackground hover:bg-silver hover:bg-opacity-35'
                }`}
              >
                <Icon
                  color={active ? 'white' : 'black'}
                  size={20}
                  htmlColor={active ? '#fff' : '#000'}
                />
                <p
                  className={`text-xl ${
                    active ? 'text-mainbackground mx-2' : 'text-[#505050] mx-2'
                  }`}
                >
                  {e.name}
                </p>
              </div>
            </Link>
          );
        })}
      </div>

      <div className="flex flex-col gap-2 justify-center items-center text-xs mb-3">
        <Link to="mailto:contact@nexushorizon.fr" className="font-semibold">
          contact@nexushorizon.fr
        </Link>
        <Link
          to="https://nexushorizon.fr/privacy-policy"
          target="_blank"
          className="font-semibold text-primary"
        >
          Privacy Policy
        </Link>
        <Link
          to="https://nexushorizon.fr/terms-and-conditions"
          target="_blank"
          className="font-semibold text-primary"
        >
          Terms & Conditions
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
