import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { HasuraServices } from '../application/protocols/services/api_services';
import {
  LocalStorageService,
  LocalStorageServiceItems,
} from '../application/storages/storage_services';
import { useProfileContext } from '../application/stores/ProfileContext';
import CustomButton from '../components/CustomButton';
import CustomTextField from '../components/CustomTextField';
import AuthHeader from '../components/widgets/AuthHeader';

// This page is used to request for a reset password code
const ForgotPasswordVerify = () => {
  const { getProfile } = useProfileContext();
  const [timer, setTimer] = useState(59);

  const navigate = useNavigate();
  const params = useParams();
  let email = params.email;
  if (!email) {
    navigate('/reset-password');
  }

  const [formData, setFormData] = useState({
    reset_code: '',
    new_password: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(() => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const query = `mutation Mymutation {
      reset_password_verify(args: {code: "${formData.reset_code}", email: "${email}", password: "${formData.new_password}"}) {
        access
        message
      }
    }
    `;
    const res = await HasuraServices.instance.query(query);
    if (res.msg.reset_password_verify) {
      if (
        res.msg.reset_password_verify.message === 'Password reset successfully.'
      ) {
        LocalStorageService.instance.set(
          LocalStorageServiceItems.ACCESS_TOKEN,
          res.msg.reset_password_verify?.access
        );
        await getProfile();
        navigate('/');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `Couldn't reset password. Please try again after sometime.`,
          footer:
            'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
        });
      }
    }

    // if (res.status) {
    //   navigate('/login');
    // }
  };

  const handleResend = async () => {
    const query = `mutation Mymutation {
      reset_password(email:"${email}"){
        access
        message
      }
    }`;
    const res = await HasuraServices.instance.query(query);
    if (res.msg.reset_password.message) {
      setTimer(59);
    }
  };
  let interval;
  useEffect(() => {
    if (timer === 59) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
        if (timer < 1) {
          clearInterval(interval);
        }
      }, 1000);
    }
  }, [timer]);

  useEffect(() => {
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="bg-mainbackground h-screen  font-times text-font ">
      <div className="justify-center items-center bg-secondbackground w-full md:w-2/3 md:max-w-[600px] mb-auto h-auto mx-auto rounded-2xl">
        <AuthHeader />
        <div className="mx-auto px-8 py-12 h-md">
          <h1 className="text-primary text-center"> Forgot Password</h1>
          <h1 className="mt-8">
            {' '}
            We have sent an email with reset code to {email}
          </h1>
          <CustomTextField
            name={'reset_code'}
            hintText={'Enter your reset code'}
            label={'Reset Code'}
            onChange={handleInputChange}
          />
          <CustomTextField
            name={'new_password'}
            hintText={'Enter your new password'}
            label={'New Password'}
            onChange={handleInputChange}
          />
          <CustomButton label="Reset Password" onClick={handleSubmit} />

          <div className="flex py-8  justify-center">
            <div
              className="px-1  font-times font-inter text-font font-bold cursor-pointer"
              onClick={handleResend}
            >
              {timer < 1 ? 'Resend' : `00 : ${timer}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordVerify;
