import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import * as React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks(props) {
  const names = props.options;

  return (
    <div className="flex  w-full">
      {props.showLabel && props.label && (
        <span className="flex items-center content-center text-md min-w-52 max-w-52 w-52">
          {props.label ?? ''}
          {' : '}
        </span>
      )}
      <div className="flex flex-col w-full text-wrap-all">
        <FormControl>
          <InputLabel id="demo-multiple-checkbox-label">
            {props.label ?? 'Tag'}
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={props.value}
            onChange={props.handleChange}
            input={<OutlinedInput label={props.label ?? 'Tag'} />}
            renderValue={(selected) => {
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    return (
                      <p
                        className={
                          'text-wrap py-2 px-3 rounded-2xl text-sm bg-gray bg-opacity-10'
                        }
                        key={value}
                      >
                        {value}{' '}
                      </p>
                    );
                  })}
                </Box>
              );
            }}
            MenuProps={MenuProps}
          >
            {names.map((name) => (
              <MenuItem key={name + '_menu_items'} value={name}>
                <Checkbox checked={props.value.includes(name)} />
                <ListItemText sx={{ textWrap: 'wrap' }} primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <span
          className={
            props.currentState.error ? 'pl-8 text-xl text-red' : 'hidden'
          }
        >
          {props.currentState.error}
        </span>
      </div>
    </div>
  );
}
