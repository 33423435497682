import { AddCircleOutline, ViewCompact } from '@mui/icons-material';
import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useAppContext } from '../../application/stores/AppContext';
import { useInnovationContext } from '../../application/stores/InnovationContext';
import CustomButton from '../../components/widgets/CustomButtonLab';

const UserPackagePage = () => {
  const { windowWidth, setLabel } = useAppContext();
  let inputValue = '';
  const { createdPackageList, insertNewCardPackage } = useInnovationContext();
  useEffect(() => {
    setLabel('Packages');
  }, []);

  if (createdPackageList.length > 0) {
    return (
      <div
        className={`bg-secondbackground ${
          windowWidth > 500 ? 'px-4' : ''
        } min-h-[83vh] max-h-[83vh] max-w-full w-full flex-col`}
      >
        <div
          className={`w-full min-h-[83vh] gap-8 flex-wrap flex flex-col bg-secondbackground rounded-lg p-4  md:p-8 ${
            windowWidth > 660 ? 'py-8' : 'pt-8'
          }`}
        >
          <div className="w-full h-fit ">
            <CustomButton
              name={'Create New Package'}
              sx={{
                minHeight: '40px',
                maxHeight: '40px',
                maxWidth: 'fit',
                minWidth: '160px',
                borderRadius: '20px',
                borderWidth: '4px',
                backgroundColor: '#521E95',
                '&:hover': {
                  backgroundColor: '#521E95ed',
                },
              }}
              handleClick={async () => {
                await Swal.fire({
                  title: 'Enter your Package Name',
                  input: 'text',
                  inputLabel: 'Your package name',
                  inputValue,
                  showCancelButton: true,
                  cancelButtonColor: '#88001B',
                  inputValidator: (value) => {
                    // Regex pattern for alphanumeric characters
                    const regex = /^[a-zA-Z0-9._@]+$/;
                    if (!value) {
                      return 'You should enter a value';
                    }
                    if (!regex.test(value)) {
                      return 'You can only enter (a-z, 0-9, A-z , . , @ , _) for package name';
                    }
                    if (createdPackageList.includes(value.toLowerCase())) {
                      return 'Package name already exists, select a unique one';
                    }
                  },
                }).then(
                  async (res) => res.value && insertNewCardPackage(res.value)
                );
              }}
              variant="contained"
              endIcon={
                <AddCircleOutline
                  sx={{ color: 'white', width: '30px', height: '30px' }}
                />
              }
            />
          </div>
          <div className="flex w-full h-full flex-wrap gap-8">
            {createdPackageList &&
              createdPackageList.map((packageItem) => (
                <CustomButton
                  name={packageItem}
                  redirectName={packageItem}
                  sx={{
                    minHeight: '100px',
                    maxHeight: '100px',
                    maxWidth: 'fit',
                    minWidth: '160px',
                    borderRadius: '8px',
                    backgroundColor: '#F4F7FE',
                    borderWidth: '4px',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#521E95',
                      color: 'white',
                    },
                  }}
                  variant="contained"
                  endIcon={
                    <ViewCompact sx={{ height: '32px', width: '32px' }} />
                  }
                />
              ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`bg-secondbackground ${
        windowWidth > 500 ? 'px-4' : ''
      } min-h-[83vh] flex  max-h-[83vh] max-w-full justify-center items-center w-full flex-col`}
    >
      <div
        className="flex flex-col w-fit h-[20vh] justify-center items-center bg-secondbackground rounded-xl p-4  md:p-12 ${
        windowWidth > 660 ? 'py-8' : 'pt-8'
      "
      >
        <span className="text-lg">Please be patient</span>
        <br />
        <span className="text-lg">
          This page is under research and will be soon available...
        </span>
      </div>
    </div>
  );
};

export default UserPackagePage;
