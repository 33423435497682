import { Close } from '@mui/icons-material';
import { Card, CardHeader, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProgressiveImage from 'react-progressive-image';
import { Link } from 'react-router-dom';
import {
  base64toBlob,
  isBase64,
} from '../application/protocols/services/utils';
import {
  LocalStorageService,
  LocalStorageServiceItems,
} from '../application/storages/storage_services';
import { useAppContext } from '../application/stores/AppContext';
import { useChatContext } from '../application/stores/ChatContext';
import { useProfileContext } from '../application/stores/ProfileContext';

const CustomSelectedImage = ({
  images,
  handleSelectedImageClick,
  setImages,
  file,
  index,
  imageWidth = '160px',
  imageHeight = '160px',
  paddingTop = '40px',
  paddingLeft = '4px',
  paddingRight = '4px',
  paddingBottom = '4px',
  closeBtnMarginRight = '-16px',
  closeBtnTop = '-8px',
  closeBtnSize = 24,
  borderRadius = '20px',
  closeBtnPadding = '8px',
  marginBottom = '0px',
  showCloseButton = true,
}) => {
  const {
    chatLoading,
    loadedImages,
    deleteUploadedDocument,
    normalizeUrl,
    fetchImage,
  } = useChatContext();
  if (typeof file != typeof '') {
    file = file.image;
  }
  const { setIsModalOpen, base_url } = useAppContext();
  const { profile } = useProfileContext();
  const [fileLink, setFileLink] = useState(file);

  const backend_file_url = base_url + 'image/';

  useEffect(() => {
    const isFileBase64 = isBase64(file);
    if (isFileBase64) {
      const fileBlob = base64toBlob(String(file));
      setFileLink(window.URL.createObjectURL(fileBlob));
    } else if (file.includes('https://')) {
      setFileLink(file);
    } else {
      setFileLink(
        `${backend_file_url}${LocalStorageService.instance.get(LocalStorageServiceItems.ACCESS_TOKEN)}/${profile.user?.id}/${file.split('/')[file.split('/').length - 1]}`
      );
    }
  }, [profile]);
  return (
    <Card
      key={index}
      style={{
        borderRadius: borderRadius,
        position: 'relative',
        paddingTop: paddingTop,
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        paddingBottom: paddingBottom,
        cursor: 'pointer',
        marginBottom: marginBottom,
        backgroundColor: '#FEFEFE',
      }}
    >
      {showCloseButton && (
        <CardHeader
          className="absolute w-full"
          action={
            <IconButton aria-label="settings" sx={{ padding: closeBtnPadding }}>
              <Close
                sx={{ width: closeBtnSize, height: closeBtnSize }}
                onClick={async () => {
                  await deleteUploadedDocument(fileLink);
                  setIsModalOpen(false);
                  setImages(
                    chatLoading
                      ? images
                      : images.filter(
                          (image, indexInsider) =>
                            image && indexInsider != index
                        )
                  );
                }}
              />
            </IconButton>
          }
          sx={{ marginRight: closeBtnMarginRight, top: closeBtnTop }}
        />
      )}
      {(() => {
        const extensions = ['.jpg', '.jpeg', '.png', 'image/jpeg', 'image/png'];
        for (let i = 0; i < extensions.length; i++) {
          if (String(file).includes(extensions[i])) {
            return (
              <ProgressiveImage
                src={
                  fileLink in loadedImages
                    ? loadedImages[file]
                    : (() => {
                        fetchImage(fileLink);
                        return fileLink;
                      })()
                }
                alt="Image"
              >
                {(src, loading) => {
                  return (
                    <img
                      src={loading ? '/favicon.ico' : src}
                      alt="Image"
                      key={index}
                      onClick={handleSelectedImageClick}
                      className="rounded-b-2xl"
                      style={{ width: imageWidth, height: imageHeight }}
                    />
                  );
                }}
              </ProgressiveImage>
            );
          }
        }
        const splittedFilename = String(file).split('.');
        let docType;
        if (
          file.includes(
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          )
        ) {
          docType = 'xlsx';
        } else {
          docType =
            splittedFilename.length > 1
              ? splittedFilename[splittedFilename.length - 1]
              : String(file).split(';')[0].split('/')[1];
        }
        if (docType == 'csv' || docType == 'xlsx') {
          docType = 'excel';
        }
        const normalizedFileUrl = normalizeUrl(file);
        const name =
          normalizedFileUrl.split('/')[normalizedFileUrl.split('/').length - 1];
        const splittedName = name.split('_');
        const filteredName = splittedName
          .filter((_splittedPartialName, index) =>
            index == splittedName.length - 1 ? false : true
          )
          .join('_');
        return (
          <div className="p-2  flex h-full justify-center items-center">
            <Link target={'_blank'} to={fileLink}>
              {normalizeUrl(filteredName)}
              <br />
              {docType.toUpperCase()} Document
            </Link>
          </div>
        );
      })()}
    </Card>
  );
};

export default CustomSelectedImage;
