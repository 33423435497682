import { Bolt, Visibility } from '@mui/icons-material';
import { FormGroup, InputBase } from '@mui/material';
import CustomInput from '../../components/CustomInput';
// import { useRef, useState } from 'react';
// import logo from '../assets/images/logo_new_0_23_22_5_2024.png';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { BiFile } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { HasuraServices } from '../../application/protocols/services/api_services';
import { useAppContext } from '../../application/stores/AppContext';
import { useCardEditContext } from '../../application/stores/CardEditContext';
import { useCollectionContext } from '../../application/stores/CollectionContext';
import { useInnovationContext } from '../../application/stores/InnovationContext';
import { useMarketContext } from '../../application/stores/MarketContext';
import CustomSelectedImage from '../../components/CustomSelectedImage';
import Section from '../../components/Section';

const Edit = (props) => {
  const [longViewAdvanced, setLongViewAdvanced] = useState(false);
  const [variables, setVariable] = useState({});
  const { setModal, modalHeight, setIsModalOpen } = useAppContext();
  const { createdCardsList, getCreatedCardList: fetchLabBots } =
    useInnovationContext();
  const { fetchBots } = useMarketContext();
  const { fetchBots: fetchCollectionBots } = useCollectionContext();

  const handleSelectedImageClick = (e) => {
    setIsModalOpen(true);
    setModal(
      <img
        src={e.target.src}
        style={{ maxHeight: modalHeight - 70 }}
        className="w-full max-w-full h-full max-h-full object-contain flex rounded-b-[26px]"
      />
    );
  };
  const [longViewGeneral, setLongViewGeneral] = useState(true);
  const { base_url } = useAppContext();

  // const fileInputRef = useRef(null);
  const routes = useParams();

  const navigate = useNavigate();

  const {
    card,
    setCard,
    cardTitle,
    setCardTitle,
    cardTitleValidator,
    detailedDescription,
    setDetailedDescription,
    detailedDescriptionValidator,
    inputTemplate,
    setInputTemplate,
    inputTemplateExample,
    setInputTemplateExample,
    outputTemplate,
    setOutputTemplate,
    outputTemplateExample,
    setOutputTemplateExample,
    name,
    setName,
    valueChanged,
    getVariablesForCardPurpose,
    variableValues,
    setVariableValues,
  } = useCardEditContext();

  useEffect(() => {
    if (name == routes.name) {
      setCard(
        createdCardsList?.filter(
          (card) =>
            card.redirect_link.split('/')[
              card.redirect_link.split('/').length - 1
            ] == name
        )[0]
      );
    } else {
      setName(routes.name);
    }
  }, [name, createdCardsList]);

  useEffect(() => {
    if (Object.keys(variables).length > 0) {
      const objectKeysVariables = Object.keys(variables);
      const newVariableValues = {};
      for (let i = 0; i < objectKeysVariables.length; i++) {
        const objectKeyVariables = objectKeysVariables[i];
        if (card.variables[objectKeyVariables]) {
          newVariableValues[objectKeyVariables] =
            card.variables[objectKeyVariables];
        }
      }
      setVariableValues((prev) => ({ ...prev, ...newVariableValues }));
    }
  }, [variables]);

  useEffect(() => {
    if (card) {
      getVariablesForCardPurpose(card?.purpose_category).then((res) =>
        setVariable(res?.data ?? {})
      );
    }
  }, [card]);

  const handleEditUpdate = async () => {
    // const updatedData = {
    //   inputTemplate: inputTemplate.value,
    //   outputTemplate: outputTemplate.value,
    //   inputTemplateExample: inputTemplateExample.value,
    //   outputTemplateExample: outputTemplateExample.value,
    //   detailed_description: detailedDescription.value,
    //   display_name: cardTitle.value,
    //   short_description: shortDescription.value,
    // };
    const input = `mutation ActionEditCard( $updated_data : UpdatedCardDataInput! ){
      edit_card(updated_data:$updated_data){
        message
        status
      }
    }`;

    const inputVariables = {
      updated_data: {
        language: 'en',
        card_id: card.id,
        display_name: cardTitle.value,
        detailed_description: detailedDescription.value,
        inputTemplate: inputTemplate.value,
        inputTemplateExample: inputTemplateExample.value,
        outputTemplate: outputTemplate.value,
        outputTemplateExample: outputTemplateExample.value,
        variables: variableValues,
      },
    };
    if (
      (await HasuraServices.instance.query(input, inputVariables)).msg.edit_card
        .status == '200'
    ) {
      await Swal.fire({
        icon: 'success',
        text: 'Card edited successfully.',
        confirmButtonColor: '#521E95',
        footer:
          'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
      });
      await fetchBots();
      await fetchCollectionBots();
      await fetchLabBots();
    } else {
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `There was some error editing your card.`,
        footer:
          'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
      });
    }
  };

  // console.log(card);

  return (
    <div
      className="px-4 md:px-5 xl:px-16 pt-0 pb-8 min-h-[100vh] overflow-y-clip w-full h-full max-w-full overflow-x-clip flex flex-col justify-center items-center"
      style={{ backgroundColor: 'whitesmoke' }}
    >
      <h1 className="text-4xl font-semibold">{props.title}</h1>

      <div className="w-full h-full flex flex-col mt-1 mr-0 overflow-y-scroll">
        <FormGroup
          sx={{
            width: '100%',
            maxWidth: '100%',
            gap: 5,
            bgcolor: 'white',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            borderRadius: '0.5rem',
            paddingTop: '40px',
            paddingBottom: '160px',
          }}
        >
          <Section longView={true} setLongView={() => {}}>
            <div className="flex max-h-72 -mb-8 justify-center items-center h-screen">
              <div className="relative">
                <img
                  priority={false}
                  width={200}
                  height={200}
                  src={
                    base_url +
                    'card-image/' +
                    card?.image_addr.split('/')[
                      card?.image_addr.split('/').length - 1
                    ]
                  }
                  alt="Selected"
                  className={`w-64 h-64 border-4 border-border border-opacity-10 object-cover rounded-lg`}
                />
              </div>

              <input
                id="file-input"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={() => {}}
              />
            </div>

            <div className="w-full flex justify-between">
              {card?.card_files && card?.card_files.length > 0 ? (
                <div className="flex w-full gap-5 items-center my-4">
                  <div className="flex h-full w-fit items-center justify-center">
                    <Button
                      endIcon={<BiFile />}
                      variant="contained"
                      type="button"
                      sx={{
                        borderRadius: 1,
                        bgcolor: '#ffffff00',
                        color: '#000',
                        maxHeight: 54,
                        cursor: 'default',
                        '&:hover': {
                          bgcolor: '#ffffff00',
                          color: '#000',
                        },
                      }}
                      className="hover:bg-transparentwhite"
                      onClick={() => {}}
                    >
                      Add Files
                    </Button>
                  </div>
                  <div className="mx-8 px-4 flex">
                    <div className="w-full flex flex-wrap gap-4 gap-y-14 bg-transparent z-20">
                      {card?.card_files?.map((image) => (
                        <CustomSelectedImage
                          file={image.image}
                          images={card?.card_files}
                          imageWidth="60px"
                          imageHeight="60px"
                          paddingTop="4px"
                          closeBtnMarginRight="-16px"
                          closeBtnTop="-12px"
                          closeBtnSize={16}
                          closeBtnPadding="4px"
                          borderRadius="10px"
                          marginBottom="-36px"
                          showCloseButton={false}
                          index={image.id}
                          handleSelectedImageClick={handleSelectedImageClick}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="flex h-full w-fit items-center justify-center">
                <Button
                  endIcon={<Visibility sx={{ color: '#5760ce' }} />}
                  variant="contained"
                  type="button"
                  sx={{
                    borderRadius: 1,
                    bgcolor: '#ffffff00',
                    color: '#000',
                    maxHeight: 54,
                    '&:hover': {
                      bgcolor: '#ffffff00',
                      color: '#000',
                    },
                  }}
                  className="hover:bg-transparentwhite"
                  onClick={() => {
                    navigate('/lab/micro-service/' + name + '/preview');
                  }}
                >
                  Preview
                </Button>
              </div>
            </div>
          </Section>

          <Section
            longView={longViewGeneral}
            setLongView={setLongViewGeneral}
            label={'General'}
            disabled={false}
          >
            <CustomInput
              currentState={cardTitle}
              setState={setCardTitle}
              sx={{
                bgcolor: '#fff',
                border: 1,
                borderRadius: 1,
                paddingLeft: 2,
                borderColor: 'GrayText',
                paddingY: 1,
              }}
              label="Title"
              validator={cardTitleValidator}
            />
            {/* <CustomInput
              label="Short Description"
              currentState={shortDescription}
              validator={shortDescriptionValidator}
              setState={setShortDescription}
              multiLine={true}
              sx={{
                bgcolor: '#fff',
                border: 1,
                borderRadius: 1,
                paddingLeft: 2,
                borderColor: 'GrayText',
                paddingY: 2,
              }}
            /> */}
            <CustomInput
              label="Detailed Description"
              currentState={detailedDescription}
              multiLine={true}
              validator={detailedDescriptionValidator}
              setState={setDetailedDescription}
              sx={{
                bgcolor: '#fff',
                border: 1,
                borderRadius: 1,
                paddingLeft: 2,
                borderColor: 'GrayText',
                paddingY: 2,
              }}
            />
            {variables &&
              Object.keys(variables).length > 0 &&
              Object.keys(variables).map((key) => {
                const setInput = (value) => {
                  setVariableValues((prev) => ({ ...prev, [key]: value }));
                };
                return (
                  <SmallInput
                    key={key}
                    hint={variables[key]}
                    input={variableValues[key]}
                    setInput={setInput}
                    label={key
                      .split('_')
                      .map(
                        (tempKeyPiece) =>
                          `${tempKeyPiece[0]}${tempKeyPiece.substring(
                            1,
                            tempKeyPiece.length
                          )}`
                      )
                      .join(' ')}
                    sx={{
                      bgcolor: '#fff',
                      border: 1,
                      borderRadius: 1,
                      paddingLeft: 2,
                      borderColor: 'GrayText',
                      paddingY: 1.5,
                    }}
                  />
                );
              })}
          </Section>

          <Section
            longView={longViewAdvanced}
            setLongView={setLongViewAdvanced}
            label="Advanced Configurations"
          >
            <CustomInput
              label="Input Template (Optional)"
              currentState={inputTemplate}
              multiLine={true}
              setState={setInputTemplate}
              sx={{
                bgcolor: '#fff',
                border: 1,
                borderRadius: 1,
                paddingLeft: 2,
                borderColor: 'GrayText',
                paddingY: 2,
              }}
            />
            <CustomInput
              label="Input Template Example (Optional)"
              currentState={inputTemplateExample}
              multiLine={true}
              setState={setInputTemplateExample}
              sx={{
                bgcolor: '#fff',
                border: 1,
                borderRadius: 1,
                paddingLeft: 2,
                borderColor: 'GrayText',
                paddingY: 2,
              }}
            />
            <CustomInput
              label="Output Template (Optional)"
              currentState={outputTemplate}
              multiLine={true}
              setState={setOutputTemplate}
              sx={{
                bgcolor: '#fff',
                border: 1,
                borderRadius: 1,
                paddingLeft: 2,
                borderColor: 'GrayText',
                paddingY: 2,
              }}
            />
            <CustomInput
              label="Output Template Example (Optional)"
              currentState={outputTemplateExample}
              multiLine={true}
              setState={setOutputTemplateExample}
              sx={{
                bgcolor: '#fff',
                border: 1,
                borderRadius: 1,
                paddingLeft: 2,
                borderColor: 'GrayText',
                paddingY: 2,
              }}
            />
          </Section>
          <Button
            endIcon={<Bolt sx={{ height: 30, width: 30 }} />}
            disabled={!valueChanged}
            className="max-w-96 min-w-80 mx-auto min-h-9 max-h-9 rounded-lg bg-primary"
            sx={{ borderRadius: 8, backgroundColor: '#521e95' }}
            variant="contained"
            type="submit"
            onClick={handleEditUpdate}
          >
            Update
          </Button>
        </FormGroup>
      </div>
    </div>
  );
};

const SmallInput = ({ input, setInput, label, hint, key, sx }) => {
  const handleChange = (e) => {
    setInput(e.target.value);
  };

  return (
    <div className="flex  w-full" key={key}>
      <span className="flex items-center content-center text-md min-w-56 max-w-56 w-56">
        {label
          ? `${label[0].toUpperCase()}${label.substring(1, label.length)}`
          : ''}
        {' : '}
      </span>
      <div className="flex flex-col w-full">
        <InputBase
          placeholder={hint}
          multiline={false}
          sx={sx}
          className="text-black"
          value={input}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Edit;
