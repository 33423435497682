import { InputBase } from '@mui/material';

const CustomInput = (props) => {
  const handleChange = (e) => {
    if (props.validator) {
      props.setState(props.validator(e.target.value));
    } else {
      props.setState((prev) => ({ ...prev, value: e.target.value }));
    }
  };
  return (
    <div className="flex  w-full">
      {props.label && (
        <span className="flex items-center content-center text-md min-w-52 max-w-52 w-52">
          {props.label ?? ''}
          {' : '}
        </span>
      )}
      <div className="flex flex-col w-full">
        <InputBase
          ref={props.ref}
          placeholder={props.currentState.hint}
          multiline={props.multiLine ?? false}
          sx={props.sx}
          value={props.currentState.value}
          onChange={handleChange}
        />
        <span
          className={
            props.currentState.error ? 'pl-8 text-xl text-red' : 'hidden'
          }
        >
          {props.currentState.error}
        </span>
      </div>
    </div>
  );
};

export default CustomInput;
