import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getUserIdFromToken } from '../protocols/guard/guard';
import { HasuraServices } from '../protocols/services/api_services';
import {
  LocalStorageService,
  LocalStorageServiceItems,
} from '../storages/storage_services';
import { useAppContext } from './AppContext';

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const navigate = useNavigate();

  const [loading, setloading] = useState(true);
  const { setLanguage } = useAppContext();
  const [profile, setProfile] = useState({});
  const [availableCards, setAvailableCards] = useState([]);
  const [userId, setUserId] = useState();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
  });

  const clearData = () => {
    setProfile({});
    setAvailableCards([]);
    setUserId();
    setFormData({
      first_name: '',
      last_name: '',
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(() => ({
      ...formData,
      [name]: value,
    }));
  };

  const changeLanguage = async (language) => {
    const doc = `mutation MyMutation{
      update_profile(_set:{language:${language}},where:{user_id:{_eq:"${profile.user?.id}"}}){
        affected_rows
      }
    }`;
    await HasuraServices.instance.query(doc, {});
  };

  const updateProfile = async (formData) => {
    const doc = `
      mutation updateprofile  {
          update_profile(_set: {first_name: "${formData.first_name}",last_name: "${formData.last_name}"}, where: {user_id:{_eq:"${profile.user.id}"}}) {
            affected_rows
            returning {
              first_name
              last_name
            }
          }
        }
      `;
    const res = await HasuraServices.instance.query(doc, {});
    if (res.status) {
      getProfile();
    }
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Str = reader.result.split(',')[1];
        resolve(base64Str);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const uploadFile = async (file) => {
    const base64Str = await getBase64(file);
    const contents = file.name.split('.');
    const fileName = contents[0];
    const fileExtension = contents[1];
    const doc = `
        mutation updateprofileimage {  
            fileUpload(base64str: "${base64Str}", file: "${fileName}", type: "${fileExtension}") {
              file_path
            }
          }
          `;
    const res = await HasuraServices.instance.query(doc);
    return res;
  };

  const updateProfileImage = async (event) => {
    setloading(true);
    const file = event.target.files[0];
    try {
      const file_res = await uploadFile(file);
      if (file_res.status) {
        const path = file_res.msg.fileUpload.file_path;
        const doc = `
                mutation updateprofileimage {
                    update_profile(_set: {profile_image: "${path}"}, where: {user_id:{_eq:"${profile.user.id}"}}) {
                      affected_rows
                      returning {
                        profile_image
                      }
                    }
                  }
                  `;
        const res = await HasuraServices.instance.query(doc);
        if (res.status) {
          getProfile();
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getProfile = async () => {
    setloading(true);
    const userId = getUserIdFromToken(
      LocalStorageService.instance.get(LocalStorageServiceItems.ACCESS_TOKEN)
    );
    setUserId(userId);
    try {
      const doc = `
      query profile {
        user_card: user_card_link(where: {user_id: {_eq: "${userId}"}}) {
          card_id
        }
        profile(where: {user_id: {_eq: "${userId}"}}) {
          acc_type
          company_name
          date_created
          dob
          first_name
          id
          last_name
          user_source
          user_type
          profile_image
          user_card_packages {
            card_package {
              cards {
                card_id
              }
            }
          }
          user {
            email
            id
            last_login
            created_at
            username
            active
            user_card_package_link {
              card_package {
                cards {
                  card_id
                }
              }
            }
          }
          language
        }
      }
      
            `;
      const res = await HasuraServices.instance.query(doc);
      if (res.msg) {
        const profileData = res.msg.profile[0];
        setProfile(profileData);
        setLanguage(profileData?.language);
        setFormData(profile);
        if (!profileData.user.active) {
          const { value } = await Swal.fire({
            icon: 'info',
            title: 'Welcome to Faxi',
            text: 'We have sent a verification link to your email.\nPlease check your emails and verify your account.',
            confirmButtonColor: '#521E95',
            footer:
              'For more information, email us at <b><a href="contact@nexushorizon.fr">contact@nexushorizon.fr</a></b>',
          });
          if (value) {
            setTimeout(async () => {
              const token = LocalStorageService.instance.get(
                LocalStorageServiceItems.ACCESS_TOKEN
              );
              if (token) {
                getProfile();
              }
            }, 1000);
          }
        }
        setAvailableCards(() => {
          //this function intends to filter out cards from cards in user_cards and user_card_packages
          //filter them out and create a non-repititive set of card blocks
          //convert their name to suitable form
          const cards = res.msg.user_card;
          const package_cards = [];
          const user_card_packages = profileData?.user_card_packages ?? [];

          //extract cards from user_card_packages and push them to package_cards
          for (let i = 0; i < user_card_packages.length; i++) {
            const card_package = user_card_packages[i].card_package;
            const temp_cards = card_package.cards;
            temp_cards.forEach((element) => {
              package_cards.push(element);
            });
          }
          const user_card_packages_from_profile =
            profileData?.user.user_card_package_link ?? [];
          for (let i = 0; i < user_card_packages_from_profile.length; i++) {
            const card_package =
              user_card_packages_from_profile[i].card_package;
            const temp_cards = card_package.cards;
            temp_cards.forEach((element) => {
              package_cards.push(element);
            });
          }
          //filter out card packages not in cards array
          const cardPackagesNotInCards = package_cards.filter((cardName) => {
            let flag = true;
            cards.forEach((tempCard) => {
              if (tempCard.card_id == cardName.card_id) {
                flag = false;
              }
            });
            return flag;
          });

          //modify card name according to the requirement
          const modifiedCards = cards.map((card) => {
            return { card: card.card_id.split('_').join('-') };
          });

          //modify card_name according to the requirement
          const modifiedCardPackagesNotInCards = cardPackagesNotInCards.map(
            (cardBlock) => {
              return { card: cardBlock.card_id.split('_').join('-') };
            }
          );

          //join two arrays and return them
          const returningArray = [
            ...modifiedCards,
            ...modifiedCardPackagesNotInCards,
          ];
          return returningArray;
        });
        setloading(false);
      } else {
        LocalStorageService.instance.delete(
          LocalStorageServiceItems.ACCESS_TOKEN
        );
        LocalStorageService.instance.delete(
          LocalStorageServiceItems.REFRESH_TOKEN
        );
        clearData();
        navigate('/');
      }
    } catch (e) {
      setloading(false);
      console.log(`error: ${e}`);
    }
  };

  useEffect(() => {
    const token = LocalStorageService.instance.get(
      LocalStorageServiceItems.ACCESS_TOKEN
    );
    if (token) {
      getProfile();
    }
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        profile,
        updateProfile,
        handleInputChange,
        updateProfileImage,
        uploadFile,
        getProfile,
        changeLanguage,
        loading,
        availableCards,
        clearData,
        userId,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = () => {
  return useContext(ProfileContext);
};
