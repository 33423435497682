import React from 'react';
import logot from '../../assets/images/logo_new_gray_background.png';

const AuthHeader = () => {
  return (
    <div className="flex flex-row justify-center items-center  font-times text-font  bg-mainbackground">
      <a href="/login">
        {' '}
        <img
          src={logot}
          height={180}
          width={180}
          alt="logo.png"
          className="m-2"
        ></img>{' '}
      </a>
    </div>
  );
};

export default AuthHeader;
