import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import { useAppContext } from '../application/stores/AppContext';
import { useProfileContext } from '../application/stores/ProfileContext';
import logo from './../assets/images/logo_without_name.png';

const Info = () => {
  const { setLabel, setHeaderImage } = useAppContext();
  const { profile } = useProfileContext();
  const { t } = useTranslation('info');

  useEffect(() => {
    setHeaderImage(logo);
    setLabel('Info Page');

    // getProfile();
  }, []);
  return (
    <div className="bg-secondbackground p-4 pb-0 pt-0 w-full h-full min-h-[83vh] max-h-[83vh]">
      <div className="flex flex-col justify-start items-start mx-1 min-h-[83vh] max-h-[83vh] w-full max-w-full  bg-secondbackground rounded-lg p-4 md:p-16 md:pb-12 overflow-scroll">
        <p className="text-xl">
          {`${t('greet_text_personal')} ${profile.first_name}👋, ${t('greet_text_official')}`}
        </p>
        <p className="text-xl mt-8">
          <b>{t('head_one')}</b>
        </p>
        <p className="text-lg mt-8">{t('para_one')}</p>
        <div className="info-markdown py-4">
          <Markdown remarkPlugins={[remarkGfm]}>{`### **Here's how Faxi works:**
* **Define your use case:** Tell Faxi what you want your micro-service to do, using simple language or specific data examples.
* **Fine-tune with knowledge:** Enhance your micro-service's capabilities by integrating your own knowledge base or leveraging Faxi's pre-built domain expertise.
* **Deploy and integrate:** Faxi seamlessly integrates with your existing infrastructure, allowing for effortless deployment and scalability.
### **Faxi empowers you to:**
* **Unlock the power of AI:** Leverage the latest advancements in artificial intelligence to build powerful, custom micro-services without writing complex code.
* **Automate complex tasks:** Streamline workflows and increase efficiency by automating tasks across various domains.
* **Focus on what matters:** Spend less time on technical details and more time on your core business objectives.
### **Who is Faxi for?**
Faxi caters to a diverse range of users:
* **Businesses:** Enhance operations, improve customer experiences, and gain a competitive edge.
* **Developers:** Accelerate development cycles and build innovative applications with ease.
* **Domain Experts:** Empower your expertise by creating AI-powered solutions without coding experience.
### **Faxi: The future of micro-service creation is here.**`}</Markdown>
        </div>
        {t('content', { returnObjects: true }).map((textBlock) => {
          if (textBlock.title != 'Power button') {
            return (
              <>
                <p className="text-lg mt-8">{textBlock.title}:</p>
                {textBlock.cards.length > 0 && (
                  <ol className="list-decimal pl-6 text-lg">
                    {textBlock.cards.map((card) => (
                      <li>
                        <div>
                          <p className="text-lg mt-10 mb-6 font-semibold">
                            {card.title}
                          </p>
                          <ul className="list-disc pl-6">
                            <li>
                              <p className="text-lg mt-4">{card.point_one}</p>
                            </li>
                            <li>
                              <p className="text-lg mt-4">{card.point_two}</p>
                            </li>
                            <li>
                              <p className="text-lg mt-4">{card.point_three}</p>
                            </li>
                            {card.point_four && (
                              <li>
                                <p className="text-lg mt-4">
                                  {card.point_four}
                                </p>
                              </li>
                            )}
                          </ul>
                        </div>
                      </li>
                    ))}
                  </ol>
                )}

                {textBlock.points.length > 0 && (
                  <ul className="list-disc pl-6">
                    {textBlock.points.map((point) => (
                      <li>
                        <p className="text-lg mt-4">{point}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            );
          } else {
            return <></>;
          }
        })}

        <p className="text-lg mt-8">{t('para_two')}</p>
        <p className="text-lg mt-8">
          {t('para_three')}{' '}
          <Link to="mailto:contact@nexushorizon.fr" className="font-semibold">
            contact@nexushorizon.fr
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Info;
