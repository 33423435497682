import { SmartButton } from '@mui/icons-material';
import { useEffect } from 'react';
import { useAppContext } from '../../application/stores/AppContext';
import { useInnovationContext } from '../../application/stores/InnovationContext';
import CustomButton from '../../components/widgets/CustomButtonLab';

export default function LabPage() {
  const { windowWidth, setLabel } = useAppContext();
  const { createdCardsList } = useInnovationContext();

  useEffect(() => {
    setLabel('Lab');
  });
  if (createdCardsList?.length > 0) {
    return (
      <div
        className={`bg-secondbackground ${
          windowWidth > 500 ? 'px-4' : ''
        } min-h-[83vh] max-h-[83vh] max-w-full w-full flex-col`}
      >
        <div
          className={`w-full h-full min-h-[83vh] bg-secondbackground rounded-lg p-4  md:p-8 ${
            windowWidth > 660 ? 'py-8' : 'pt-8'
          }`}
        >
          <section className="flex w-full h-full p-4 flex-wrap min-w-full gap-24 justify-start items-center">
            <CustomButton
              name="User-package Management"
              redirectLink={'/lab/user-micro-service'}
              redirectName="User-package"
              variant="contained"
              sx={{
                minHeight: '200px',
                maxWidth: '400px',
                minWidth: '400px',
                borderRadius: '16px',
                color: 'black',
                backgroundColor: '#F4F7FE',
                '&:hover': {
                  backgroundColor: '#521E95',
                  color: 'white',
                },
              }}
              endIcon={<SmartButton sx={{ width: '30px', height: '30px' }} />}
            />

            <CustomButton
              name="Micro-service Management"
              redirectLink={'/lab/micro-service'}
              redirectName="Micro-service"
              variant="contained"
              sx={{
                minHeight: '200px',
                maxWidth: '400px',
                minWidth: '400px',
                borderRadius: '16px',
                color: 'black',
                backgroundColor: '#F4F7FE',
                '&:hover': {
                  backgroundColor: '#521E95',
                  color: 'white',
                },
              }}
              endIcon={<SmartButton sx={{ width: '30px', height: '30px' }} />}
            />
          </section>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`bg-mainbackground ${
        windowWidth > 500 ? 'px-4' : ''
      } min-h-[83vh] flex  max-h-[83vh] max-w-full justify-center items-center w-full flex-col`}
    >
      <div
        className="flex flex-col w-fit h-[20vh] justify-center items-center bg-secondbackground rounded-xl p-4  md:p-12 ${
        windowWidth > 660 ? 'py-8' : 'pt-8'
      "
      >
        <span className="text-lg">Please be patient</span>
        <br />
        <span className="text-lg">
          This page is under research and will be soon available...
        </span>
      </div>
    </div>
  );
}
