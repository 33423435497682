import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CustomButton = ({
  name,
  variant,
  endIcon,
  redirectName,
  sx,
  redirectLink,
  handleClick = null,
}) => {
  const navigate = useNavigate();
  const handleClickNative = () => {
    if (redirectName || redirectLink) {
      navigate(
        redirectLink ??
          `/lab/user-micro-service/${redirectName?.toLowerCase()?.split(' ')?.join('-')}`
      );
    }
  };
  return (
    <Button
      name={name}
      onClick={handleClick ?? handleClickNative}
      variant={variant}
      endIcon={endIcon}
      sx={sx}
    >
      {name}
    </Button>
  );
};

export default CustomButton;
