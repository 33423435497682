import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datetime-picker/dist/DateTimePicker.css';

import ReactDatePicker from 'react-datepicker';

const CustomDateTimePicker = ({ onChange, value, label = '' }) => {
  return (
    <div className="flex flex-col items-start mx-0">
      <label
        htmlFor="textfield"
        className="text-xl font-times text-font mb-2"
      >{`${label}`}</label>
      <div className=" p-2 w-full flex flex-col font-times  border border-fs  rounded-md hover:border-primary">
        <ReactDatePicker
          onChange={onChange}
          selected={value}
          className="w-full   focus:outline-none  "
        />
        {/* <DateTimePicker
          onChange={onChange}
          value={value}
          showTimeSelect={false}
          className="w-full py-2 mt-1 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200"
        /> */}
        {/* <div className="w-24 bg-secondbackground z-10 mb-4 -mt-8 min-h-5 ml-[76px]"></div> */}
      </div>
    </div>
  );
};

export default CustomDateTimePicker;
