import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAppContext } from './../stores/AppContext';

import axios from 'axios';
import {
  LocalStorageService,
  LocalStorageServiceItems,
} from '../storages/storage_services';
import { HasuraServices } from './../protocols/services/api_services';
import { useInnovationContext } from './InnovationContext';
import { useProfileContext } from './ProfileContext';

// const extractString = (input) => {
//   return String(input) == 'null' ? '' : String(input);
// };

const CardEditContext = createContext({
  cardTitle: {
    value: '',
    error: '',
    hint: 'Data Matching and Categorization',
  },
  setCardTitle: () => null,
  shortDescription: {
    value: '',
    error: '',
    hint: 'Matching datasets and categorizing entries based on predefined rules',
  },
  setShortDescription: () => null,
  detailedDescription: {
    value: '',
    error: '',
    hint: 'Automate the process of matching and categorizing data entries across multiple datasets. It uses machine learning algorithms to identify relationships between different data points, match records with high accuracy, and assign categories based on predefined criteria. This can be useful for tasks like deduplication, merging datasets, customer segmentation, or categorizing transactions.',
  },
  setDetailedDescription: () => null,
  userEmail: {
    value: '',
    error: '',
    hint: `user@faxi.ai
      `,
  },
  setUserEmail: () => null,
  inputTemplate: {
    value: '',
    error: '',
    hint: 'user@faxi.ai',
  },
  packageName: {
    value: '',
    error: '',
    hint: 'Faxi',
  },
  setPackageName: () => null,
  setInputTemplate: () => null,
  inputTemplateExample: {
    value: '',
    error: '',
    hint: `{
    "Dataset A": "CustomerData.csv",
    "Dataset B": "OrderData.csv",
    "Matching Criteria": ["Email", "Customer ID"],
    "Categorization Rules": {
      "Region": "Based on customer's address",
      "Transaction Type": "Categorize based on order type"
    }
}
  `,
  },
  setInputTemplateExample: () => null,
  outputTemplate: {
    value: '',
    error: '',
    hint: `Matched Records: List of records from both datasets that have been successfully matched.
Unmatched Records: List of records that could not be matched between the datasets.
Categorized Data: The matched data entries categorized according to the specified rules.`,
  },
  setOutputTemplate: () => null,
  outputTemplateExample: {
    value: '',
    error: '',
    hint: `{
    "Matched Records": [
      {
        "Customer ID": "12345", 
        "Email": "john.doe@example.com", 
        "Order ID": "A001"
      },
      {
        "Customer ID": "67890", 
        "Email": "jane.doe@example.com", 
        "Order ID": "B002"
      }
    ],
    "Unmatched Records": [
      {"Customer ID": "54321", "Email": "mike.smith@example.com"}
    ],
    "Categorized Data": {
      "Region": {"North America": 120, "Europe": 80},
      "Transaction Type": {"Product Purchase": 150, "Subscription": 50}
    }
}`,
  },
  setOutputTemplateExample: () => null,
  resetForm: () => {},
  cardTitleValidator: () => {
    return {
      value: '',
      error: '',
      hint: '',
    };
  },
  shortDescriptionValidator: () => {
    return {
      value: '',
      error: '',
      hint: '',
    };
  },
  detailedDescriptionValidator: () => {
    return {
      value: '',
      error: '',
      hint: '',
    };
  },
  userEmailValidator: () => {
    return {
      value: '',
      error: '',
      hint: '',
    };
  },
  cardCreationGoingOn: false,
  setCardCreationGoingOn: () => {},
  addedFiles: [],
  setAddedFiles: () => {},
  progress: 0,
  setProgress: () => {},
  handleSelectedFileChange: () => {},
  createCardNewMethod: () => {},
  handleSelectedImageClick: () => {},
  fetchUploadedFiles: () => {},
  languageList: [],
  setLanguageList: () => {},
  imageAnalysisChecked: ['No'],
  setImageAnalysisChecked: () => {},
  textAnalysisChecked: ['Yes'],
  setTextAnalysisChecked: () => {},
  tableAnalysisChecked: ['Yes'],
  setTableAnalysisChecked: () => {},
  purpose: ['General Purpose (Q&A from course material)'],
  setPurpose: () => {},
  accuracy: ['Perfect'],
  setAccuracy: () => {},
  userEmailList: [],
  setUserEmailList: () => {},
  getVariablesForCardPurpose: () => {},
});

export const CardEditContextProvider = ({ children }) => {
  const [userEmailList, setUserEmailList] = useState([]);
  const [languageList, setLanguageList] = React.useState(['English']);
  const [name, setName] = useState('');
  const [valueChanged, setValueChanged] = useState(false);
  const [imageAnalysisChecked, setImageAnalysisChecked] = React.useState([
    'No',
  ]);
  const [accuracy, setAccuracy] = React.useState(['Perfect']);
  const [purpose, setPurpose] = React.useState([]);
  const [textAnalysisChecked, setTextAnalysisChecked] = React.useState(['Yes']);
  const [tableAnalysisChecked, setTableAnalysisChecked] = React.useState([
    'Yes',
  ]);

  const {
    windowWidth,
    windowHeight,
    setModal,
    setIsModalOpen,
    modalHeight,
    setModalHeight,
    setModalWidth,
    base_url,
  } = useAppContext();

  const { createdCardsList } = useInnovationContext();

  const [progress, setProgress] = useState(2);
  const [addedFiles, setAddedFiles] = useState([]);

  const [cardCreationGoingOn, setCardCreationGoingOn] = useState(false);

  const [card, setCard] = useState();

  const { userId } = useProfileContext();

  const [cardTitle, setCardTitle] = useState({
    value: '',
    error: '',
    hint: 'Data Matching and Categorization',
  });

  const [shortDescription, setShortDescription] = useState({
    value: '',
    error: '',
    hint: 'Matching datasets and categorizing entries based on predefined rules',
  });

  const [detailedDescription, setDetailedDescription] = useState({
    value: '',
    error: '',
    hint: 'Automate the process of matching and categorizing data entries across multiple datasets. It uses machine learning algorithms to identify relationships between different data points, match records with high accuracy, and assign categories based on predefined criteria. This can be useful for tasks like deduplication, merging datasets, customer segmentation, or categorizing transactions.',
  });

  const [userEmail, setUserEmail] = useState({
    value: '',
    error: '',
    hint: 'user@faxi.ai',
  });

  const [packageName, setPackageName] = useState({
    value: '',
    error: '',
    hint: 'Faxi',
  });

  const [inputTemplate, setInputTemplate] = useState({
    value: '',
    error: '',
    hint: `Dataset A: The first dataset to be matched (e.g., a CSV file of customer records).
    Dataset B: The second dataset to be matched (e.g., a list of orders or transactions).
    Matching Criteria: A set of parameters (e.g., "Name", "Email", "Transaction ID") used for matching records between the datasets.
    Categorization Rules: Rules to categorize matched data (e.g., by region, transaction type, or customer segment).
          `,
  });

  const [inputTemplateExample, setInputTemplateExample] = useState({
    value: '',
    error: '',
    hint: `{
          "Dataset A": "CustomerData.csv",
          "Dataset B": "OrderData.csv",
          "Matching Criteria": ["Email", "Customer ID"],
          "Categorization Rules": {
            "Region": "Based on customer's address",
            "Transaction Type": "Categorize based on order type"
          }
    }
        `,
  });

  const [outputTemplate, setOutputTemplate] = useState({
    value: '',
    error: '',
    hint: `Matched Records: List of records from both datasets that have been successfully matched.
    Unmatched Records: List of records that could not be matched between the datasets.
    Categorized Data: The matched data entries categorized according to the specified rules.`,
  });

  const [outputTemplateExample, setOutputTemplateExample] = useState({
    value: '',
    error: '',
    hint: `{
          "Matched Records": [
            {
              "Customer ID": "12345", 
              "Email": "john.doe@example.com", 
              "Order ID": "A001"
            },
            {
              "Customer ID": "67890", 
              "Email": "jane.doe@example.com", 
              "Order ID": "B002"
            }
          ],
          "Unmatched Records": [
            {"Customer ID": "54321", "Email": "mike.smith@example.com"}
          ],
          "Categorized Data": {
            "Region": {"North America": 120, "Europe": 80},
            "Transaction Type": {"Product Purchase": 150, "Subscription": 50}
          }
    }`,
  });

  const [variableValues, setVariableValues] = useState({});

  const cardTitleValidator = (input) => {
    let error = '';
    if (input) {
      if (input.includes(':')) {
        error = 'Card Title cannot contain : symbol';
      }
      if (input.includes('/')) {
        error = 'Card Title cannot contain / symbol';
      }
      if (input.length < 4) {
        error = 'Card Title too short. It should be atleast 4 digits long';
      }
    }
    return { value: input, error: error, hint: 'Card Title' };
  };

  const shortDescriptionValidator = (input) => {
    let error = '';
    if (input) {
      if (input.split(' ').length < 3) {
        error = 'Short description should atleast have three words';
      }
      if (input.length > 65) {
        error =
          'Short description should not be this long. Keep it below 66 characters.';
      }
    }
    return {
      value: input,
      error: error,
      hint: 'Short description for card',
    };
  };

  const detailedDescriptionValidator = (input) => {
    let error = '';
    if (input) {
      if (input.split('').length < 2) {
        error = 'Description should at least have one word';
      }
    }
    return {
      value: input,
      error: error,
      hint: 'Description for card',
    };
  };

  const userEmailValidator = (input) => {
    const alphanumericRegex = /^[a-zA-Z0-9@,.\n ]+$/;
    let error = '';
    if (input) {
      if (!alphanumericRegex.test(input)) {
        error =
          "Email can only contain alphanumeric characters, '@' symbol and '.' symbol";
      } else if (input.includes(' ')) {
        error = 'Email cannot contain spaces';
      } else if (input.includes(',')) {
        error = "Email cannot contain ',' symbol";
      } else if (input.split('@').length != 2) {
        error = "Email must contain one '@' symbol";
      } else if (input.split('@')[1].length < 5) {
        error = "Email must contain domain (e.g., '@faxi.ai').";
      } else if (
        input.split('@')[1].split('.').length < 2 ||
        input.split('@')[1].split('.')[0].length < 3 ||
        input.split('@')[1].split('.')[1].length < 2
      ) {
        error = "Email must contain valid domain (e.g., '@faxi.ai').";
      }
    }
    return {
      value: input,
      error: error,
      hint: 'user@faxi.ai',
    };
  };

  function resetForm() {
    setCardTitle({
      value: '',
      error: '',
      hint: 'Card Title',
    });
    setShortDescription({
      value: '',
      error: '',
      hint: 'Short description for card',
    }),
      setDetailedDescription({
        value: '',
        error: '',
        hint: 'Automate the process of matching and categorizing data entries across multiple datasets. It uses machine learning algorithms to identify relationships between different data points, match records with high accuracy, and assign categories based on predefined criteria. This can be useful for tasks like deduplication, merging datasets, customer segmentation, or categorizing transactions.',
      });
    setInputTemplate({
      value: '',
      error: '',
      hint: `Dataset A: The first dataset to be matched (e.g., a CSV file of customer records).
        Dataset B: The second dataset to be matched (e.g., a list of orders or transactions).
        Matching Criteria: A set of parameters (e.g., "Name", "Email", "Transaction ID") used for matching records between the datasets.
        Categorization Rules: Rules to categorize matched data (e.g., by region, transaction type, or customer segment).
              `,
    });
    setInputTemplateExample({
      value: '',
      error: '',
      hint: `{
            "Dataset A": "CustomerData.csv",
            "Dataset B": "OrderData.csv",
            "Matching Criteria": ["Email", "Customer ID"],
            "Categorization Rules": {
              "Region": "Based on customer's address",
              "Transaction Type": "Categorize based on order type"
            }
      }
          `,
    });
    setOutputTemplate({
      value: '',
      error: '',
      hint: `Matched Records: List of records from both datasets that have been successfully matched.
        Unmatched Records: List of records that could not be matched between the datasets.
        Categorized Data: The matched data entries categorized according to the specified rules.`,
    });
    setOutputTemplateExample({
      value: '',
      error: '',
      hint: `{
            "Matched Records": [
              {
                "Customer ID": "12345", 
                "Email": "john.doe@example.com", 
                "Order ID": "A001"
              },
              {
                "Customer ID": "67890", 
                "Email": "jane.doe@example.com", 
                "Order ID": "B002"
              }
            ],
            "Unmatched Records": [
              {"Customer ID": "54321", "Email": "mike.smith@example.com"}
            ],
            "Categorized Data": {
              "Region": {"North America": 120, "Europe": 80},
              "Transaction Type": {"Product Purchase": 150, "Subscription": 50}
            }
      }`,
    });
  }

  const handleSelectedFileChange = (event) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        // const filePath = await uploadDocument(
        //   String(reader.result).split(',')[1],
        //   file.name
        // );
        // if (filePath) {
        //   //   const file_temp = convertUrl(filePath);
        //   //   fetchFile(filePath);
        //   //   setAddedFiles((prev) => {
        //   //     return [...prev, { image: file_temp }];
        //   //   });
        // }
      };
      reader.readAsDataURL(file);
    }
  };

  const getVariablesForCardPurpose = async (purpose) => {
    const purposeUrlVariables =
      base_url +
      'purpose-variables/' +
      purpose
        ?.split('')
        .map((char) => {
          // Check if the character is a-z, A-Z, or 0-9
          if (/^[a-zA-Z0-9]$/.test(char)) {
            return char; // Keep it as it is
          } else {
            return '-'; // Replace it with "gpt"
          }
        })
        .join('');

    const result = await axios.get(purposeUrlVariables, {
      headers: {
        Authorization: `Bearer ${LocalStorageService.instance.get(
          LocalStorageServiceItems.ACCESS_TOKEN
        )}`,
      },
    });

    return result;
  };

  const handleSelectedImageClick = (e) => {
    e.preventDefault();
    setModalWidth;
    setIsModalOpen(true);
    setModalWidth(0.7 * windowWidth);
    setModalHeight(0.8 * windowHeight);
    setModal(
      <Image
        alt={''}
        width={400}
        height={400}
        src={e.target.src}
        style={{ maxHeight: modalHeight - 70 }}
        className="w-full max-w-full h-full max-h-full object-contain flex rounded-b-[26px]"
      />
    );
  };

  const fetchUploadedFiles = () => {
    if (userId) {
      const cardCreationProcessMutation = `query ImportCardFiles($where: card_file_bool_exp!) {
      card_file(where: $where, order_by: {created_at: desc}) {
        url_name
      }
    }`;
      const variables = {
        where: {
          user_id: {
            _eq: userId,
          },
          status: {
            _eq: 'inserted',
          },
        },
      };
      HasuraServices.instance
        .query(cardCreationProcessMutation, variables)
        .then((res) => {
          if (res.msg) {
            const fileList = res.msg.card_file.map((cardFileArrayItem) => {
              return { image: cardFileArrayItem.url_name };
            });
            setAddedFiles(fileList);
          }
        });
    }
  };

  useEffect(() => {
    const card = createdCardsList?.filter(
      (card) =>
        card.redirect_link.split('/')[
          card.redirect_link.split('/').length - 1
        ] == name
    )[0];
    setCardTitle({
      value: card?.display_name,
      error: '',
      hint: 'Data Matching and Categorization',
    });
    // setShortDescription({
    //   value: card?.short_description,
    //   error: '',
    //   hint: 'Matching datasets and categorizing entries based on predefined rules',
    // });
    setDetailedDescription({
      value: card?.detailed_description,
      error: '',
      hint: 'Automate the process of matching and categorizing data entries across multiple datasets. It uses machine learning algorithms to identify relationships between different data points, match records with high accuracy, and assign categories based on predefined criteria. This can be useful for tasks like deduplication, merging datasets, customer segmentation, or categorizing transactions.',
    });
    setInputTemplate({
      value: card?.inputTemplate,
      error: '',
      hint: `Dataset A: The first dataset to be matched (e.g., a CSV file of customer records).
Dataset B: The second dataset to be matched (e.g., a list of orders or transactions).
Matching Criteria: A set of parameters (e.g., "Name", "Email", "Transaction ID") used for matching records between the datasets.
Categorization Rules: Rules to categorize matched data (e.g., by region, transaction type, or customer segment).
                `,
    });
    setInputTemplateExample({
      value: card?.inputTemplateExample,
      error: '',
      hint: `{
    "Dataset A": "CustomerData.csv",
    "Dataset B": "OrderData.csv",
    "Matching Criteria": ["Email", "Customer ID"],
    "Categorization Rules": {
        "Region": "Based on customer's address",
        "Transaction Type": "Categorize based on order type"
    }
}
            `,
    });

    setOutputTemplate({
      value: card?.outputTemplate,
      error: '',
      hint: `Matched Records: List of records from both datasets that have been successfully matched.
Unmatched Records: List of records that could not be matched between the datasets.
Categorized Data: The matched data entries categorized according to the specified rules.`,
    });
    setOutputTemplateExample({
      value: card?.outputTemplateExample,
      error: '',
      hint: `{
    "Matched Records": [  
        {
            "Customer ID": "12345", 
            "Email": "john.doe@example.com", 
            "Order ID": "A001"
        },
        {
            "Customer ID": "67890", 
            "Email": "jane.doe@example.com", 
            "Order ID": "B002"
        }
    ],
    "Unmatched Records": [
        {"Customer ID": "54321", "Email": "mike.smith@example.com"}
    ],
    "Categorized Data": {
        "Region": {"North America": 120, "Europe": 80},
        "Transaction Type": {"Product Purchase": 150, "Subscription": 50}
    }
}`,
    });
  }, [createdCardsList, name]);

  useEffect(() => {
    const card = createdCardsList?.filter(
      (card) =>
        card.redirect_link.split('/')[
          card.redirect_link.split('/').length - 1
        ] == name
    )[0];

    let purposeVariableChanged = false;
    const variableValuesKeys = Object.keys(variableValues);
    for (let i = 0; i < variableValuesKeys.length; i++) {
      const tempKey = variableValuesKeys[i];
      if (card?.variables[tempKey]) {
        if (variableValues[tempKey] == card?.variables[tempKey]) {
          purposeVariableChanged = false;
          continue;
        } else {
          purposeVariableChanged = true;
          break;
        }
      }
    }

    if (
      (cardTitle.value ?? '')?.trim() == (card?.display_name ?? '') &&
      (detailedDescription.value ?? '')?.trim() ==
        (card?.detailed_description ?? '') &&
      (outputTemplate.value ?? '')?.trim() == (card?.outputTemplate ?? '') &&
      (inputTemplate.value ?? '')?.trim() == (card?.inputTemplate ?? '') &&
      (inputTemplateExample.value ?? '')?.trim() ==
        (card?.inputTemplateExample ?? '') &&
      (outputTemplateExample.value ?? '')?.trim() ==
        (card?.outputTemplateExample ?? '') &&
      !purposeVariableChanged
    ) {
      setValueChanged(false);
    } else {
      setValueChanged(true);
    }
    // if (
    //   (cardTitle.value != extractString(card?.display_name) ||
    //     // shortDescription.value != extractString(card?.short_description) ||
    //     detailedDescription.value !=
    //       extractString(card?.detailed_description) ||
    //     outputTemplate.value != extractString(card?.outputTemplate) ||
    //     outputTemplateExample.value !=
    //       extractString(card?.outputTemplateExample) ||
    //     inputTemplate.value != extractString(card?.inputTemplate) ||
    //     inputTemplateExample.value !=
    //       extractString(card?.inputTemplateExample)) &&
    //   cardTitleValidator(cardTitle.value).error.trim().length === 0 &&
    //   // shortDescriptionValidator(shortDescription.value).error.trim().length ===
    //   //   0 &&
    //   detailedDescriptionValidator(detailedDescription.value).error.trim()
    //     .length === 0
    // ) {
    //   setValueChanged(true);
    // } else {
    //   setValueChanged(false);
    // }
  }, [
    name,
    createdCardsList,
    cardTitle,
    shortDescription,
    detailedDescription,
    inputTemplate,
    inputTemplateExample,
    outputTemplate,
    outputTemplateExample,
    variableValues,
  ]);

  return (
    <CardEditContext.Provider
      value={{
        cardTitle,
        setCardTitle,
        shortDescription,
        setShortDescription,
        detailedDescription,
        setDetailedDescription,
        userEmail,
        setUserEmail,
        userEmailList,
        setUserEmailList,
        inputTemplate,
        setInputTemplate,
        inputTemplateExample,
        setInputTemplateExample,
        outputTemplate,
        setOutputTemplate,
        outputTemplateExample,
        setOutputTemplateExample,
        resetForm,
        cardTitleValidator,
        shortDescriptionValidator,
        detailedDescriptionValidator,
        userEmailValidator,
        progress,
        setProgress,
        addedFiles,
        setAddedFiles,
        cardCreationGoingOn,
        setCardCreationGoingOn,
        handleSelectedFileChange,
        handleSelectedImageClick,
        fetchUploadedFiles,
        languageList,
        setLanguageList,
        imageAnalysisChecked,
        setImageAnalysisChecked,
        textAnalysisChecked,
        setTextAnalysisChecked,
        tableAnalysisChecked,
        setTableAnalysisChecked,
        purpose,
        setPurpose,
        accuracy,
        setAccuracy,
        packageName,
        setPackageName,
        name,
        setName,
        valueChanged,
        getVariablesForCardPurpose,
        variableValues,
        setVariableValues,
        card,
        setCard,
      }}
    >
      {children}
    </CardEditContext.Provider>
  );
};

export const useCardEditContext = () => {
  return useContext(CardEditContext);
};
