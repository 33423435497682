import React from 'react';
import AuthCard from '../components/widgets/AuthCard';
import AuthHeader from '../components/widgets/AuthHeader';

const Login = () => {
  return (
    <div className="bg-mainbackground h-full ">
      <div className="justify-center items-center bg-secondbackground w-full  md:w-2/5 mb-auto h-auto mx-auto rounded-xl">
        <AuthHeader />
        <AuthCard />
        <div className="flex pb-8  justify-center">
          <p className="font-times text-font">Forgot Password?</p>
          <a
            href="/forgot-password"
            className="px-1  font-times font-inter text-font font-bold"
          >
            {' '}
            Reset{' '}
          </a>
        </div>
        <div className="text-center  mb-12 pb-20 ">
          <h5> For more information, email us at </h5>{' '}
          <a href="mailto:contact@nexushorizon.fr">
            <h5 className="font-bold text-primary"> contact@nexushorizon.fr</h5>{' '}
          </a>
        </div>
      </div>

      {/* Pricing */}
      {/* <div className='flex flex-col justify-center items-center mt-12'>
        <h1 className='text-4xl font-times text-font font-bold'>Pricing</h1>
        <h1 className='text-xl font-times text-font font-semibold mb-4'>Always know ahead what you will pay.</h1>
        <div className='flex flex-row justify-center items-center hover:cursor-pointer'>
          {billing.map((e, index) => {
            const active = selectedIndex === index;
            const bg = active ?'bg-primary text-white border border-fs':'bg-secondbackground text-black border border-fs'
            const radius = index === 0 ? 'rounded-tl-xl rounded-bl-xl ':'rounded-br-xl rounded-tr-xl'
            const style =  `${bg} ${radius} h-8 p-1 font-times` ;
            return (<h1 key={index} className={style} onClick={()=>setselectedIndex(index)}> {e}</h1>)
          })}
        </div>
        <div className='flex flex-row justify-evenly items-stretch'>
          {subscription.map((e, index) => {
            return <SubscriptionCard key={index} index={selectedIndex} e={e} onClick={null} />
          })}
        </div>
      </div> */}
      {/* End of Pricing */}
    </div>
  );
};

export default Login;
