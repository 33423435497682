import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { generateAlphanumericString } from '../protocols/guard/guard';
import { HasuraServices } from '../protocols/services/api_services';
import { useAppContext } from './AppContext';
import { useProfileContext } from './ProfileContext';

const MarketContext = createContext();

export const StoreProvider = ({ children }) => {
  const { language, base_url } = useAppContext();
  const [query, setQuery] = useState('');
  const [originals, setOriginals] = useState([]);
  const { availableCards } = useProfileContext();
  const [fetchedOriginalData, setFetchedOriginalData] = useState({
    en: null,
    de: null,
    fr: null,
  });

  const [bots, setBots] = useState(originals);

  const fetchBots = async () => {
    const query = `query MyQUery($language:languages_enum!){
      frontend_card_config(where:{language:{_eq: $language}}, order_by:{card:{created_at:desc}}){
        card_id
        config_info
        config_main
        card{
          created_at
          category
        }
      }
    }`;
    const variables = {
      language: language,
    };
    if (!fetchedOriginalData[language]) {
      HasuraServices.instance.query(query, variables).then((res) => {
        const generatedOriginals = res.msg?.frontend_card_config
          .map((card_config_info) => ({
            ...card_config_info.config_main,
            image_addr: `${base_url}card-image${card_config_info.config_main.image_addr}`,
            actionAreaKey: generateAlphanumericString(4),
            created_date: card_config_info.card.created_at,
            purpose_category: card_config_info.card.category,
          }))
          .map((cardData, index) => ({
            ...cardData,
            rate:
              cardData.redirect_link.split('/')[2] == 'summarization'
                ? 1500
                : 1000 - index,
          }));
        setOriginals(generatedOriginals);
        setFetchedOriginalData({
          ...fetchedOriginalData,
          [language]: generatedOriginals,
        });
      });
    } else {
      setOriginals(
        fetchedOriginalData[language].sort(
          (first, second) => second.rate - first.rate
        )
      );
    }
  };

  const fetchBot = async (card_id) => {
    const query = `query MyQUery($card_id:String!){
      frontend_card_config(where:{card_id:{_eq:$card_id}}, order_by:{card:{created_at:desc}}){
        card_id
        config_info
        config_main
        card{
          created_at
          category
        }
      }
    }`;
    const variables = {
      card_id: card_id,
    };
    const res = await HasuraServices.instance.query(query, variables);
    const generatedOriginals = res.msg?.frontend_card_config
      .map((card_config_info) => ({
        ...card_config_info.config_main,
        image_addr: `${base_url}card-image${card_config_info.config_main.image_addr}`,
        actionAreaKey: generateAlphanumericString(4),
        created_date: card_config_info.card.created_at,
        purpose_category: card_config_info.card.category,
      }))
      .map((cardData, index) => ({
        ...cardData,
        rate:
          cardData.redirect_link.split('/')[2] == 'summarization'
            ? 1500
            : 1000 - index,
      }));
    return generatedOriginals;
  };

  const updateCollection = useCallback(async () => {
    const collectedConfiguration = [];
    for (let i = 0; i < availableCards.length; i++) {
      const availableCard = availableCards[i];
      if (
        fetchedOriginalData[language].filter(
          (tempCard) =>
            String(tempCard.redirect_link).split('/')[2] == availableCard.card
        ).length < 1
      ) {
        const newConfig = await fetchBot(
          availableCard.card.split('-').join('_')
        );
        if (newConfig && newConfig[0])
          collectedConfiguration.push(newConfig[0]);
      }
    }
    return collectedConfiguration;
  }, [fetchedOriginalData]);

  useEffect(() => {
    setTimeout(() => {
      if (
        availableCards.length > 0 &&
        fetchedOriginalData[language] &&
        fetchedOriginalData[language].length
      ) {
        updateCollection().then((res) => {
          if (res.length > 0) {
            setFetchedOriginalData((prev) => {
              return {
                ...prev,
                [language]: [...(prev[language] ?? []), ...res],
              };
            });
            setOriginals([...(fetchedOriginalData[language] ?? []), ...res]);
          }
        });
      }
    }, 2000);
  }, [availableCards, fetchedOriginalData]);

  const forceFetchBots = async () => {
    const query = `query MyQUery($language:languages_enum!){
      frontend_card_config(where:{language:{_eq: $language}}, order_by:{card:{created_at:desc}}){
        card_id
        config_info
        config_main
        card{
          category
          created_at
        }
      }
    }`;
    const variables = {
      language: language,
    };
    HasuraServices.instance.query(query, variables).then((res) => {
      const generatedOriginals = res.msg?.frontend_card_config
        .map((card_config_info) => ({
          ...card_config_info.config_main,
          image_addr: `${base_url}card-image${card_config_info.config_main.image_addr}`,
          actionAreaKey: generateAlphanumericString(4),
          created_date: card_config_info.card.created_at,
          purpose_category: card_config_info.card.category,
        }))
        .map((cardData, index) => ({
          ...cardData,
          rate:
            cardData.redirect_link.split('/')[2] == 'summarization'
              ? 1500
              : 1000 - index,
        }));
      setOriginals(generatedOriginals);
      setFetchedOriginalData({
        ...fetchedOriginalData,
        [language]: generatedOriginals,
      });
    });
  };

  const clearData = () => {
    setOriginals([]);
    setFetchedOriginalData({
      en: null,
      de: null,
      fr: null,
    });
  };

  useEffect(() => {
    fetchBots();
  }, [language]);

  useEffect(() => setBots(originals), [originals]);

  const rateBots = () => {
    let ratedBots = [];
    let tempBots = fetchedOriginalData[language]
      .sort(
        (first, second) =>
          new Date(second.created_date) - new Date(first.created_date)
      )
      .map((cardData, index) => ({
        ...cardData,
        rate:
          cardData.redirect_link.split('/')[2] == 'summarization'
            ? 1500
            : 1000 - index,
      }));
    const addRate = tempBots.length;
    tempBots.forEach((bot) => {
      let character = '';
      // let shortDescription = bot.short_description.toLowerCase();
      let detailedDescription = bot.detailed_description.toLowerCase();
      let displayName = bot.display_name.toLowerCase();
      for (let index = 0; index < query.trim().length; index++) {
        character = query.trim().charAt(index);
        if (displayName.includes(character)) {
          bot.rate = bot.rate + addRate * 4;
        }
        // if (shortDescription.includes(character)) {
        // bot.rate = bot.rate + addRate * 2;
        // }
        if (detailedDescription.includes(character)) {
          bot.rate = bot.rate + addRate * 1;
        }
      }
      if (displayName.includes(query.trim())) {
        bot.rate = bot.rate + addRate * 4 * query.trim().length;
      }
      // if (shortDescription.includes(query.trim())) {
      //   bot.rate = bot.rate + addRate * 2 * query.trim().length;
      // }
      if (detailedDescription.includes(query.trim())) {
        bot.rate = bot.rate + addRate * 1 * query.trim().length;
      }
      ratedBots.push(bot);
    });
    setBots(ratedBots);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    if (query.trim() == '') {
      setBots(
        fetchedOriginalData[language]
          ?.sort(
            (first, second) =>
              new Date(second.created_date) - new Date(first.created_date)
          )
          ?.map((cardData, index) => {
            return {
              ...cardData,
              rate:
                cardData?.redirect_link.split('/')[2] == 'summarization'
                  ? 1500
                  : 1000 - index,
            };
          })
      );
    } else {
      rateBots();
    }
  }, [query, fetchedOriginalData]);

  return (
    <MarketContext.Provider
      value={{
        query,
        setQuery,
        bots,
        fetchBots,
        forceFetchBots,
        handleQueryChange,
        clearData,
        fetchBot,
      }}
    >
      {children}
    </MarketContext.Provider>
  );
};

export const useMarketContext = () => {
  return useContext(MarketContext);
};
