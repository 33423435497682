import React from 'react';
import AuthCard from '../components/widgets/AuthCard';
import AuthHeader from '../components/widgets/AuthHeader';

const Register = () => {
  return (
    <div className="bg-mainbackground h-screen font-times text-font">
      <div className="justify-center items-center bg-secondbackground w-3/5 mb-auto h-auto mx-auto rounded-xl">
        <AuthHeader />
        <AuthCard />
        {/* <div className='flex py-2 justify-center'>
            <p>Forgot Password?</p>
          <a href='/forgot-password' className='px-1 font-inter text-font'>  Reset </a>
          </div> */}
      </div>
    </div>
  );
};

export default Register;
