import { Close } from '@mui/icons-material';
import { Card, CardHeader, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { useAppContext } from '../application/stores/AppContext';

const CustomBaseModal = () => {
  const {
    modal,
    isModalOpen,
    setModal,
    setIsModalOpen,
    modalHeight,
    modalWidth,
  } = useAppContext();

  useEffect(() => {
    if (!isModalOpen) {
      setModal();
    }
  }, [isModalOpen]);

  return (
    <div
      className={
        isModalOpen
          ? 'absolute w-full h-full max-w-full flex justify-center items-center max-h-full top-0 left-0 bg-mainbackground bg-opacity-50 '
          : 'hidden'
      }
    >
      <Card
        sx={{
          maxWidth: modalWidth,
          maxHeight: modalHeight,
          borderRadius: 8,
          padding: '4px',
          flexDirection: 'column',
          alignItems: 'start',
          display: 'flex',
        }}
      >
        <CardHeader
          className=" w-full"
          action={
            <IconButton
              aria-label="settings"
              onClick={() => setIsModalOpen(false)}
            >
              <Close />
            </IconButton>
          }
          sx={{ marginRight: -4, maxHeight: 70, height: 70 }}
        />
        <div
          className={`h-full   max-w-full flex rounded-b-[26px] `}
          style={{ maxHeight: modalHeight - 70 }}
        >
          {modal}
        </div>
      </Card>
    </div>
  );
};

export default CustomBaseModal;
