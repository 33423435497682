import { Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { BiPlusCircle } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { HasuraServices } from '../../application/protocols/services/api_services';
import { useAppContext } from '../../application/stores/AppContext';
import { useInnovationContext } from '../../application/stores/InnovationContext';
import MultipleSelectCheckmarks from '../../components/MultiSelectCheckMark';
import CustomButton from '../../components/widgets/CustomButtonLab';
import logo from './../../assets/images/logo_without_name.png';
import CustomButtonMain from './../../components/CustomButton';
import CustomInput from './../../components/widgets/lab/CustomInput';
// import { WorkOutlineOutlined } from '@mui/icons-material';

/**
 * UserMicroServicePage
 */
const UserMicroServicePage = () => {
  const {
    setModalWidth,
    setModal,
    setIsModalOpen,
    windowWidth,
    setLabel,
    setHeaderImage,
    routes: appRoutes,
    setRoutes,
    isModalOpen,
  } = useAppContext();
  const [userEmail, setUserEmail] = useState({
    value: '',
    error: '',
    hint: 'user@faxi.ai',
  });
  const [tempUserEmailList, setTempUserEmailList] = useState([]);
  const [userEmailList, setUserEmailList] = useState([
    'user@faxi.ai',
    'user2@faxi.ai',
    'user3@faxi.ai',
    'user4@faxi.ai',
  ]);

  const [needToAddUser, setNeedToAddUser] = useState(false);
  const [tempMicroServiceList, setTempMicroServiceList] = React.useState([]);
  const { createdCardsList } = useInnovationContext();
  const [outputTemplateExample, setOutputTemplateExample] = useState({
    value: '',
    error: '',
    hint: `Matched Records: List of records from both datasets that have been successfully matched.
    Unmatched Records: List of records that could not be matched between the datasets.
    Categorized Data: The matched data entries categorized according to the specified rules.`,
  });

  /**
   * Handle new input to the add micro-service form
   * @param {*} event - on change event for the select
   */
  const handleSelectedTempMicroServiceListChange = (event) => {
    const {
      target: { value },
    } = event;
    setTempMicroServiceList(
      // On autofill we get a stringified value.
      typeof value === 'string'
        ? value
            .split(',')
            .filter((microServiceName) => microServiceName != 'summarization')
        : value.filter(
            (microServiceName) => microServiceName != 'summarization'
          )
    );
  };

  const routes = useParams();
  const name = routes.userPackageName;
  // let defaultInputValueForSwal = '';

  /**
   * Add current route to app routes as this route is a dynamic one
   * Set title of page to 'Package'
   */
  useEffect(() => {
    setHeaderImage(logo);
    if (!appRoutes.includes(`/lab/user-micro-service/${name}`)) {
      setRoutes((prev) => [...prev, `/lab/user-micro-service/${name}`]);
    }
    setLabel('Package');
  }, [name]);

  const [microServiceList, setMicroServiceList] = useState([
    'Faxi Micro-service',
    'Faxi Micro-service Version 2',
  ]);

  /**
   * Fetch:
   * 1. Email of users who have access to the package
   * 2. List of micro-services that have been added to the package
   */
  const fetchUserEmailAndMicroServiceList = () => {
    const query = `query MyQuery {
    card_package_by_pk(package_id: "${name}") {
      cards{
        card_id
      }
      user_card_packages{
        profile{
          user{
            email
          }
        }
        user{
          email
        }
      }
    }
  }`;
    HasuraServices.instance.query(query).then((res) => {
      // Extract micro-service from the response and add them to microServiceList
      setMicroServiceList(
        res.msg.card_package_by_pk.cards.map(
          (microService) => microService.card_id
        )
      );

      // Extract email from the response and add them to userEmailList
      setUserEmailList(
        res.msg.card_package_by_pk.user_card_packages
          .map((user_card_package) =>
            user_card_package?.profile
              ? user_card_package?.profile?.user?.email
              : user_card_package?.user?.email
          )
          .filter((mail) => mail)
      );
    });
  };

  /**
   * Insert list of emails for linking user to card package asynchronously
   * @param {*} emailList - List of emails who need access to the package
   */
  const insertUserEmailToUserEmailList = async (emailList) => {
    const mutation = `mutation MyMutation($user_card_package_link:[user_card_package_link_insert_input!]!){
      insert_user_card_package_link(objects:$user_card_package_link){
        returning{
          email
        }
      }
    }`;

    const variables = {
      user_card_package_link: emailList.map((email) => ({
        email: email,
        package_id: name,
      })),
    };
    await HasuraServices.instance.query(mutation, variables);
    setTempUserEmailList([]);
    setUserEmail({
      hint: 'user@faxi.ai',
      error: '',
      value: '',
    });
  };

  /**
   * Fetch Email Micro-service list for the package
   */
  useEffect(() => {
    fetchUserEmailAndMicroServiceList();
  }, []);

  /**
   * Validate if the email in the user email form is a valid email
   * and return the email input object for new state
   * @param {*} input - input from user email form
   * @returns {*} {value - value of user email, hint - hint for the user email form, error - will be present if any errors are detected}
   */
  const userEmailValidator = (input) => {
    const alphanumericRegex = /^[a-zA-Z0-9@.\n ;]+$/;
    let error = '';
    if (input) {
      if (!alphanumericRegex.test(input)) {
        error =
          "Email can only contain alphanumeric characters, '@' symbol and '.' symbol";
      } else if (input.includes(' ')) {
        error = 'Email cannot contain spaces';
      } else if (input.includes(',')) {
        error = "Email cannot contain ',' symbol";
      } else if (input.split('@').length != 2) {
        error = "Email must contain one '@' symbol";
      } else if (input.split('@')[1].length < 5) {
        error = "Email must contain domain (e.g., '@faxi.ai').";
      } else if (
        input.split('@')[1].split('.').length < 2 ||
        input.split('@')[1].split('.')[0].length < 3 ||
        input.split('@')[1].split('.')[1].length < 2
      ) {
        error = "Email must contain valid domain (e.g., '@faxi.ai').";
      } else if (userEmailList.includes(input)) {
        error = 'This email has already been assigned to this package';
      }
    }
    return { ...userEmail, value: input, error };
  };

  /**
   * Set Modal to view add user email form
   */
  const setModalWithAddUserForm = useCallback(() => {
    setModal(
      <div className="flex flex-col mx-4 mb-4">
        <div className="flex justify-center mb-4 text-lg">
          Enter email of users separated by ; sign
        </div>
        <CustomInput
          currentState={userEmail}
          multiLine={true}
          validator={userEmailValidator}
          setState={setUserEmail}
          sx={{
            bgcolor: '#fff',
            border: 1,
            borderRadius: 1,
            paddingLeft: 2,
            borderColor: 'GrayText',
            paddingY: 2,
          }}
        />
        {tempUserEmailList.length > 0 && (
          <div className="flex pl-2 w-full mb-2 items-start">
            {/* <span className="flex items-center mt-4 content-center text-md min-w-52 max-w-52 w-52"></span> */}
            <div className="flex flex-col w-full">
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                  padding: 1,
                }}
              >
                {tempUserEmailList.map((value) => (
                  <div
                    key={value}
                    className="text-wrap py-2 px-3 rounded-2xl text-sm bg-gray bg-opacity-10 flex gap-2 justify-center items-center"
                  >
                    <p>{value}</p>
                    <div className="rounded-2xl px-[2px] bg-secondbackground">
                      <Close
                        sx={{ width: 16, height: 16 }}
                        className="opacity-35 hover:opacity-65 cursor-pointer"
                        onClick={() => {
                          setTempUserEmailList((prev) => {
                            return prev.filter(
                              (email) =>
                                email.toLowerCase() != value.toLowerCase()
                            );
                          });
                        }}
                      />
                    </div>
                  </div>
                ))}
              </Box>
            </div>
          </div>
        )}
        <div className={'w-full flex gap-8 mt-4 justify-evenly'}>
          <CustomButtonMain
            label={'Add'}
            disabled={tempUserEmailList.length < 1}
            onClick={async () => {
              await insertUserEmailToUserEmailList(tempUserEmailList);
              await fetchUserEmailAndMicroServiceList();
              setIsModalOpen(false);
            }}
            style={
              tempUserEmailList.length > 0
                ? 'bg-primary w-full h-10 text-mainbackground font-bold py-2 px-6 rounded-md hover:bg-blue-600 focus:ring focus:ring-blue-200'
                : 'bg-primary cursor-not-allowed w-full h-10 text-mainbackground font-bold py-2 px-6 rounded-md hover:bg-blue-600 focus:ring focus:ring-blue-200 bg-opacity-20'
            }
          />
          <CustomButtonMain
            label={'Cancel'}
            onClick={() => {
              setIsModalOpen(false);
            }}
            style={
              'bg-warning w-full h-10 text-mainbackground font-bold py-2 px-6 rounded-md hover:bg-blue-600 focus:ring focus:ring-blue-200'
            }
          />
        </div>
      </div>
    );
  }, [userEmail, tempUserEmailList]);

  /**
   * Set Modal to view add micro-service form
   */
  const setModalWithAddMicroServiceForm = useCallback(() => {
    setModalWidth(900);
    setModal(
      <div className="pb-4 px-4 flex flex-col w-full min-w-[760px]">
        <div className="flex justify-center mb-4 text-lg">
          Select the micro-services to be added in your package
        </div>
        <MultipleSelectCheckmarks
          options={createdCardsList
            ?.filter(
              (createdCard) => !microServiceList.includes(createdCard.id)
            )
            .map((createdCard) => createdCard.id)}
          label="Micro-service List"
          value={tempMicroServiceList}
          currentState={outputTemplateExample}
          multiLine={true}
          handleChange={handleSelectedTempMicroServiceListChange}
          setState={setOutputTemplateExample}
          sx={{
            bgcolor: '#fff',
            border: 1,
            borderRadius: 1,
            paddingLeft: 2,
            borderColor: 'GrayText',
            paddingY: 2,
          }}
        />
        <div className={'w-full flex gap-8 mt-8 justify-evenly'}>
          <CustomButtonMain
            label={'Add'}
            disabled={tempMicroServiceList.length < 1}
            onClick={async () => {
              const addMicroServiceToPackageMutation = `mutation MyMutation($card_package_cards_link:[card_package_cards_link_insert_input!]!){
                insert_card_package_cards_link(objects:$card_package_cards_link){
                  returning{
                    card_id
                    card_package_id
                  }
                }
              }`;
              const variables = {
                card_package_cards_link: tempMicroServiceList.map((accu) => ({
                  card_id: accu,
                  card_package_id: name,
                })),
              };
              await HasuraServices.instance.query(
                addMicroServiceToPackageMutation,
                variables
              );
              setTempMicroServiceList([]);
              setIsModalOpen(false);
              fetchUserEmailAndMicroServiceList();
            }}
            style={
              tempMicroServiceList.length > 0
                ? 'bg-primary w-full h-10 text-mainbackground font-bold py-2 px-6 rounded-md hover:bg-blue-600 focus:ring focus:ring-blue-200'
                : 'bg-primary cursor-not-allowed w-full h-10 text-mainbackground font-bold py-2 px-6 rounded-md hover:bg-blue-600 focus:ring focus:ring-blue-200 bg-opacity-20'
            }
          />
          <CustomButtonMain
            label={'Cancel'}
            onClick={() => {
              setIsModalOpen(false);
            }}
            style={
              'bg-warning w-full h-10 text-mainbackground font-bold py-2 px-6 rounded-md hover:bg-blue-600 focus:ring focus:ring-blue-200'
            }
          />
        </div>
      </div>
    );
  }, [createdCardsList, microServiceList, tempMicroServiceList]);

  /**
   * Check if the entered user email is a valid email
   * If yes:
   *     - add emails to the userEmailList
   *     - clear out the user email form
   * else:
   *     - do nothing
   */
  useEffect(() => {
    if (
      userEmail.value[userEmail.value.length - 1] == '\n' &&
      userEmailValidator(userEmail.value.split('\n')[0]).error == ''
    ) {
      setTempUserEmailList((prev) => {
        if (
          prev.includes(userEmail.value.split('\n')[0].toLowerCase()) ||
          userEmailList.includes(userEmail.value.split('\n')[0].toLowerCase())
        ) {
          setUserEmail(() => ({
            hint: 'user@faxi.ai',
            error: '',
            value: '',
          }));
          return prev;
        }
        setUserEmail(() => ({
          hint: 'user@faxi.ai',
          error: '',
          value: '',
        }));
        return [...prev, userEmail.value.split('\n')[0]];
      });
    } else if (
      userEmail.value[userEmail.value.length - 1] == ' ' &&
      userEmailValidator(userEmail.value.split(' ')[0]).error == ''
    ) {
      setTempUserEmailList((prev) => {
        if (
          prev.includes(userEmail.value.split(' ')[0].toLowerCase()) ||
          userEmailList.includes(userEmail.value.split(' ')[0].toLowerCase())
        ) {
          setUserEmail(() => ({
            hint: 'user@faxi.ai',
            error: '',
            value: '',
          }));
          return prev;
        }
        setUserEmail(() => ({
          hint: 'user@faxi.ai',
          error: '',
          value: '',
        }));
        return [...prev, userEmail.value.split(' ')[0]];
      });
    } else if (
      userEmail.value[userEmail.value.length - 1] == ';' &&
      userEmailValidator(userEmail.value.split(';')[0]).error == ''
    ) {
      setTempUserEmailList((prev) => {
        if (prev.includes(userEmail.value.split(';')[0].toLowerCase())) {
          setUserEmail(() => ({
            hint: 'user@faxi.ai',
            error: '',
            value: '',
          }));
          return prev;
        }
        setUserEmail(() => ({
          hint: 'user@faxi.ai',
          error: '',
          value: '',
        }));
        return [...prev, userEmail.value.split(';')[0]];
      });
    } else if (userEmail.value != '' && userEmail.value.includes('\n')) {
      setUserEmail((prev) => ({
        ...prev,
        value: prev.value.trim(),
        error: 'This email has already been assigned to this package',
      }));
    }
  }, [userEmail]);

  /**
   * Check if the user is in user-micro-service-page before updating view for modal
   */
  useEffect(() => {
    if (
      Object.entries(routes)[0][1].includes('lab/user-micro-service/') &&
      isModalOpen
    ) {
      if (needToAddUser) {
        setModalWithAddUserForm();
      } else {
        setModalWithAddMicroServiceForm();
      }
    }
  }, [
    isModalOpen,
    tempMicroServiceList,
    needToAddUser,
    userEmail,
    tempUserEmailList,
    microServiceList,
  ]);

  /**
   * Update tempMicroServiceList to the first value of options in
   * micro-service selection list in add micro-service form
   */
  useEffect(() => {
    if (createdCardsList && createdCardsList.length > 0) {
      // setTempMicroServiceList(
      //   createdCardsList
      //     ?.filter((value) => !microServiceList.includes(value.id))
      //     ?.filter((value, index) => value && index == 0)
      //     .map((createdCard) => createdCard.id)
      // );
      setTempMicroServiceList([]);
    }
  }, [createdCardsList, microServiceList]);

  if (createdCardsList?.length > 0) {
    return (
      <div
        className={`bg-secondbackground ${
          windowWidth > 500 ? 'px-4' : ''
        } min-h-[83vh] max-h-[83vh] max-w-full w-full flex-col`}
      >
        <div
          className={`w-full gap-4 h-fit bg-secondbackground items-center justify-center rounded-lg p-4 flex md:pt-8  `}
        >
          <span className="text-xl font-semibold">Package Name: </span>
          <span className="text-xl font-semibold">{name}</span>
        </div>
        <div
          className={`w-full grid-cols-2 gap-4 grid-rows-1 h-full min-h-[83vh] bg-secondbackground rounded-lg p-4 grid md:p-8 ${
            windowWidth > 660 ? 'py-8' : 'pt-8'
          }`}
        >
          <section className="flex flex-col w-full h-full pb-4 min-w-full justify-start items-start rounded-lg">
            <div className="w-full flex justify-start bg-secondbackground px-4 pb-4 text-2xl font-semibold">
              Micro-service ({microServiceList.length})
            </div>
            <div className="w-full h-full max-h-[80vh] flex flex-col p-4 pb-24 rounded-lg bg-mainbackground">
              <div className="w-full h-fit flex justify-start text-white">
                <CustomButton
                  name={'Add micro-service'}
                  sx={{
                    minHeight: '40px',
                    maxHeight: '40px',
                    maxWidth: 'fit',
                    minWidth: '160px',
                    borderRadius: '20px',
                    borderWidth: '4px',
                    backgroundColor: '#521E95',
                    '&:hover': {
                      backgroundColor: '#521E95ed',
                    },
                  }}
                  handleClick={() => {
                    setNeedToAddUser(false);
                    // Swal.fire({
                    //   title: 'Select an option',
                    //   html: `
                    //     <select id="swal-select" className="swal2-input">
                    //     ${createdCardsList
                    //       ?.map((cardData) => ({
                    //         code: cardData.redirect_link
                    //           .split('/')[2]
                    //           .split('-')
                    //           .join('_'),
                    //       }))
                    //       .filter(
                    //         (cardDataTitleAndCode) =>
                    //           !microServiceList.includes(
                    //             cardDataTitleAndCode.code
                    //           )
                    //       )
                    //       .map(
                    //         (cardDataTitleAndCode) =>
                    //           `<option value="${cardDataTitleAndCode.code}">${cardDataTitleAndCode.code.trim()}</option>`
                    //       )}
                    //     </select>
                    //   `,
                    //   showCancelButton: true,
                    //   cancelButtonColor: '#88001B',
                    //   confirmButtonText: 'Submit',
                    //   preConfirm: () => {
                    //     const selectElement =
                    //       document.getElementById('swal-select');
                    //     return selectElement.value;
                    //   },
                    // }).then(async (result) => {
                    //   if (result.isConfirmed) {
                    //     const addMicroServiceToPackageMutation = `mutation MyMutation{
                    //       insert_card_package_cards_link_one(object:{card_id:"${result.value}",card_package_id:"${name}"}){
                    //         card_id
                    //         card_package_id
                    //       }
                    //     }`;
                    //     await HasuraServices.instance.query(
                    //       addMicroServiceToPackageMutation
                    //     );
                    //     fetchUserEmailAndMicroServiceList();
                    //   }
                    // });
                    setIsModalOpen(true);
                  }}
                  variant="contained"
                  endIcon={<BiPlusCircle size={30} />}
                />
              </div>
              <div className="w-full h-full mt-4 rounded-lg bg-secondbackground  overflow-y-auto">
                <div className="flex flex-col w-full">
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 0.5,
                      padding: 1,
                    }}
                  >
                    {microServiceList.map((value) => (
                      <div
                        key={value}
                        className="text-wrap py-2 px-3 rounded-2xl text-sm bg-gray bg-opacity-10 flex gap-2 justify-center items-center"
                      >
                        <p>{value}</p>
                        <div className="rounded-2xl max-h-5 min-h-5 max-w-5 min-w-5 flex items-center justify-center bg-secondbackground">
                          <Close
                            sx={{ width: 16, height: 16 }}
                            className="opacity-35 hover:opacity-65 cursor-pointer"
                            onClick={async () => {
                              const result = await Swal.fire({
                                text: `Are you sure you want to remove ${value} from ${name}?`,
                                showCancelButton: true,
                                cancelButtonColor: '#88001B',
                                confirmButtonText: 'Yes',
                                confirmButtonColor: '#521E95',
                              });
                              if (result.isConfirmed) {
                                const deleteMicroServiceFromPackage = `mutation MyMutation{
                                  delete_card_package_cards_link(where:{card_package_id:{_eq:"${name}"},card_id:{_eq:"${value}"}}){
                                    affected_rows
                                  }
                                }`;
                                await HasuraServices.instance.query(
                                  deleteMicroServiceFromPackage
                                );
                                fetchUserEmailAndMicroServiceList();
                              }
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </section>
          <section className="flex flex-col w-full h-full pb-4 min-w-full justify-start items-start rounded-lg">
            <div className="w-full flex justify-start bg-secondbackground px-4 pb-4 text-2xl font-semibold">
              User ({userEmailList.length})
            </div>
            <div className="w-full h-full flex flex-col p-4 rounded-lg bg-mainbackground max-h-[80vh]">
              <div className="w-full h-fit flex justify-start text-white">
                <CustomButton
                  name={'Add user'}
                  handleClick={async () => {
                    setNeedToAddUser(true);
                    setIsModalOpen(true);
                    // const { value } = await Swal.fire({
                    //   title: "Enter new user's email",
                    //   input: 'text',
                    //   inputLabel: "New user's email",
                    //   inputValue: defaultInputValueForSwal,
                    //   showCancelButton: true,
                    //   cancelButtonColor: '#88001B',
                    //   inputValidator: (value) => {
                    //     if (value.length < 1) {
                    //       return 'Please enter an email address';
                    //     }
                    //     const result = userEmailValidator(value);
                    //     if (result != '') {
                    //       return result;
                    //     }
                    //     if (userEmailList.includes(value)) {
                    //       return 'This email is already present in the package, please select a unique one';
                    //     }
                    //   },
                    // });
                    // await insertUserEmailToUserEmailList(value);
                  }}
                  sx={{
                    minHeight: '40px',
                    maxHeight: '40px',
                    maxWidth: 'fit',
                    minWidth: '160px',
                    borderRadius: '20px',
                    borderWidth: '4px',
                    backgroundColor: '#521E95',
                    '&:hover': {
                      backgroundColor: '#521E95ed',
                    },
                  }}
                  variant="contained"
                  endIcon={<BiPlusCircle size={30} />}
                />
              </div>
              <div className="w-full h-full mt-4 rounded-lg bg-secondbackground pb-24 overflow-y-auto">
                <div className="flex flex-col w-full">
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 0.5,
                      padding: 1,
                    }}
                  >
                    {userEmailList.map((value) => (
                      <div
                        key={value}
                        className="text-wrap py-2 px-3 rounded-2xl text-sm bg-gray bg-opacity-10 flex gap-2 justify-center items-center"
                      >
                        <p>{value}</p>
                        <div className="rounded-2xl max-h-5 min-h-5 max-w-5 min-w-5 flex items-center justify-center bg-secondbackground">
                          <Close
                            sx={{ width: 16, height: 16 }}
                            className="opacity-35 hover:opacity-65 cursor-pointer"
                            onClick={async () => {
                              const result = await Swal.fire({
                                text: `Are you sure you want to remove ${value} from ${name}?`,
                                showCancelButton: true,
                                cancelButtonColor: '#88001B',
                                confirmButtonText: 'Yes',
                                confirmButtonColor: '#521E95',
                              });
                              if (result.isConfirmed) {
                                const deletionMutation = `mutation MyMutation($email:String!){
                                  delete_user_card_package_link(where:{_and:[{_or:[{email:{_eq:$email}},{profile:{user:{email:{_eq:$email}}}}]},{package_id:{_eq:"${name}"}}]}){
                                    affected_rows
                                  }
                                }`;
                                await HasuraServices.instance.query(
                                  deletionMutation,
                                  { email: value }
                                );
                                fetchUserEmailAndMicroServiceList();
                              }
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`bg-secondbackground ${
        windowWidth > 500 ? 'px-4' : ''
      } min-h-[83vh] flex  max-h-[83vh] max-w-full justify-center items-center w-full flex-col`}
    >
      <div
        className="flex flex-col w-fit h-[20vh] justify-center items-center bg-secondbackground rounded-xl p-4  md:p-12 ${
        windowWidth > 660 ? 'py-8' : 'pt-8'
      "
      >
        <span className="text-lg">Please be patient</span>
        <br />
        <span className="text-lg">
          This page is under research and will be soon available...
        </span>
      </div>
    </div>
  );
};

// const BagIconBackgroundWide = ({ style, label }) => {
//   const svgPath = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 24" fill="#521E95ca" stroke="black" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
//   <path d="M18 8V7a6 6 0 0 1 12 0v1"/>
//   <rect x="4" y="8" width="40" height="16" rx="2" ry="2"/>
//   </svg>`;
//   const svgDataUrl = `url("data:image/svg+xml,${encodeURIComponent(svgPath)}")`;
//   return (
//     <div
//       style={{
//         ...style,
//         backgroundImage: svgDataUrl,
//         color: 'black',
//         backgroundRepeat: 'no-repeat',
//         backgroundPosition: 'center',
//         backgroundSize: 'contain',
//         paddingTop: '96px',
//         paddingLeft: '9%',
//       }}
//     >
//       <span className="text-white flex min-h-20 max-w-[80%] font-semibold items-center justify-center content-center">
//         {label}
//       </span>
//     </div>
//   );
// };

export default UserMicroServicePage;
