import { ArrowBackIos } from '@mui/icons-material';

const Section = ({
  children,
  longView,
  setLongView,
  label = '',
  disabled = false,
}) => {
  return (
    <div className="flex w-full  flex-col justify-center items-center px-4">
      {label && (
        <div
          className="w-full text-[1.375rem] font-medium text-left flex min-w-[100%] max-w-full"
          style={{
            marginBottom: longView ? '50px' : '0px',
          }}
        >
          <div
            className="w-full min-w-full flex justify-between bg-section items-center cursor-pointer border-2 border-black border-opacity-15 rounded-xl px-3"
            onClick={() => setLongView(!longView)}
          >
            <span className="text-mainbackground text-lg font-semibold ">
              {label}
            </span>
            <ArrowBackIos
              style={{
                color: disabled ? 'lightgrey' : 'white',
              }}
              className={longView ? '-rotate-90 mb-3' : 'rotate-180'}
            />
          </div>
        </div>
      )}
      {longView && (
        <div
          style={{
            width: '100%',
            maxWidth: '100%',
            gap: '40px',
            backgroundColor: 'white',
            padding: 8,
            paddingLeft: 15,
            paddingRight: 15,
            borderRadius: 8,
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Section;
