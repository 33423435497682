import React, { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '../application/stores/AppContext';
import CustomCard from '../components/CustomCard';
// import cardsFile from './../assets/files/storeCards.json';

import { useMarketContext } from '../application/stores/MarketContext';
import { useProfileContext } from '../application/stores/ProfileContext';
import logo from './../assets/images/logo_without_name.png';

const Store = () => {
  const { setLabel, windowWidth, setHeaderImage } = useAppContext();
  const { bots } = useMarketContext();
  const { availableCards } = useProfileContext();
  const [x, setX] = useState(4);
  const [filteredAndSortedBots, setFilteredAndSortedBots] = useState([]);

  useEffect(() => {
    dataSortingAndFiltering();
  }, [bots, availableCards]);

  const dataSortingAndFiltering = useCallback(() => {
    const newBots = bots
      ?.filter((bot) => {
        //filter out cards and validate cards that are available to the user
        const cardId = bot.redirect_link.substring(1).split('/')[1];
        let flag = false;
        availableCards.forEach((availableCard) => {
          if (availableCard.card == cardId) {
            flag = true;
          }
        });
        // if (profile?.acc_type == 'user_trial') flag = true;
        return flag;
      })
      .sort((first, second) => second.rate - first.rate);
    let flag = false;

    const listOfDisplayNames = filteredAndSortedBots
      ? filteredAndSortedBots.map((newTempBot) => newTempBot.display_name)
      : [];
    if (newBots ? newBots.length : 0 != listOfDisplayNames.length) {
      flag = true;
    }

    newBots?.forEach((newTempBot, index) => {
      const extractedDisplayName = listOfDisplayNames[index];
      if (extractedDisplayName != newTempBot.display_name) {
        flag = true;
      }
    });

    if (flag) {
      return setFilteredAndSortedBots(newBots);
    }
  }, [filteredAndSortedBots, bots]);

  useEffect(() => {
    setLabel('');
    setHeaderImage(logo);
  }, []);
  // const [customFlexCode, setCustomFlexCode] = useState(<></>);

  useEffect(() => {
    if (windowWidth >= 1949) {
      setX(5);
    }
    if (windowWidth >= 1570) {
      setX(4);
    }
    if (windowWidth < 1570) {
      setX(3);
    }
    if (windowWidth < 1150) {
      setX(2);
    }
    if (windowWidth < 800) {
      setX(1);
    }
  }, [windowWidth]);

  return (
    <div
      className={`bg-secondbackground ${
        windowWidth > 500 ? 'px-4' : ''
      } min-h-[83vh] max-h-[83vh] max-w-full w-full flex-col`}
    >
      <div
        className={`w-full h-full bg-secondbackground rounded-lg p-4  md:p-8 ${
          windowWidth > 660 ? 'py-8' : 'pt-8'
        }`}
      >
        {/* <CustomSearchField
          textarea={false}
          value={query}
          hintText={'Search in results..'}
          onChange={(ev) => setQuery(ev.target.value)}
          className="max-w-96"
        /> */}
        {bots && (
          <div
            className={`grid bg-secondbackground ${
              x === 5
                ? bots.length < x
                  ? 'grid-cols-4'
                  : 'grid-cols-5'
                : x === 4
                  ? bots.length < x
                    ? 'grid-cols-3'
                    : 'grid-cols-4'
                  : x === 3
                    ? 'grid-cols-3'
                    : x === 2
                      ? 'grid-cols-2'
                      : 'grid-cols-1'
            } gap-12 items-start pl-3 min-h-[83vh] max-h-[83vh] w-full max-w-full pb-12 max-md:pb-36 md:pr-7  overflow-y-scroll`}
          >
            {/* sort available assistants and add them to display */}
            {filteredAndSortedBots.map((bot) => (
              <div
                key={bot.actionAreaKey}
                className="p-2  flex items-center justify-center"
              >
                <CustomCard cardProps={bot} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Store;
