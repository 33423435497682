import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../application/stores/AppContext';
import { useChatContext } from '../application/stores/ChatContext';
import ChatbotResponse from '../components/ChatbotResponse';
import DropZone from '../components/containers/DropZone';
import logo from './../assets/images/logo_without_name.png';

const Favourite = () => {
  const { setLabel, setHeaderImage, windowWidth } = useAppContext();
  const {
    query,
    setQuery,
    oldFavouriteResponses,
    isFavouritePage,
    setIsFavouritePage,
  } = useChatContext();
  // const { t } = useTranslation('global');
  // const sendRef = useRef(null);
  const [_textareaHeight, setTextareaHeight] = useState(0);

  useEffect(() => {
    if (!query.trim()) {
      //if query only contains a enter, delete the query
      setQuery('');
    }
    let count = 0;
    for (let i = 0; i < query.length; i++) {
      if (query[i] == '\n') {
        count++;
      }
    }
    setTextareaHeight(`${20 * (count + 1)}px`);
  }, [query]);

  const routes = useParams();

  useEffect(() => {
    if (routes[Object.keys(routes)[0]] == 'favorite') {
      setIsFavouritePage(true);
    } else {
      setIsFavouritePage(false);
    }
  }, [routes]);

  useEffect(() => {
    setHeaderImage(logo);
    setLabel('Favorite');

    // getProfile();
  }, []);

  return (
    <DropZone id={'dropzone'} className="w-full h-full ">
      <div className="bg-secondbackground md:p-4 pb-0 pt-0 flex max-w-full h-full min-h-[83vh] max-h-[83vh]">
        <div className="flex flex-col justify-start items-center mx-1 min-h-[83vh] max-h-[83vh] w-full max-w-full  bg-secondbackground rounded-lg p-4 py-12 md:p-16 md:pb-12">
          <div className="max-h-full w-full max-w-full max-md:pb-12 md:pr-4 overflow-y-scroll">
            {oldFavouriteResponses && oldFavouriteResponses.length > 0 ? (
              oldFavouriteResponses.map((oldResponse) => (
                <ChatbotResponse
                  isFavouritePage={isFavouritePage}
                  keyID={oldResponse.id}
                  images={oldResponse.images}
                  query={oldResponse.query}
                  response={oldResponse.response}
                  style={{ marginTop: windowWidth > 768 ? 12 : 4 }}
                  className={'max-w-full'}
                  favourite={oldResponse.fav}
                  chatDate={oldResponse.chatDate}
                  cardName={oldResponse.cardName}
                />
              ))
            ) : (
              <b>You have not added any items to your favourites yet.</b>
            )}
          </div>
        </div>
      </div>
    </DropZone>
  );
};

export default Favourite;
