import React from 'react';

const ImageUploadButton = ({ label, onImageSelect }) => {
  return (
    <div className=" bg-primary w-full h-55 text-mainbackground font-bold py-2 px-4 rounded hover:bg-blue-600 focus:ring focus:ring-blue-200">
      <label htmlFor="imageUpload" className="cursor-pointer">
        <p className="text-sm text-gray-600">{label}</p>
      </label>
      <input
        type="file"
        id="imageUpload"
        accept=".jpg, .jpeg, .png"
        className="hidden"
        onChange={(ev) => onImageSelect(ev)}
      />
    </div>
  );
};

export default ImageUploadButton;
